import React from "react";
import ResumeImage from "../src/Images/ResumeReviewOptional2.png";
import RecruitersImage from "../src/Images/RecruiterReviewFinal.png";
import InterviewImage from "../src/Images/InterviewFinal.png";
import OfferImage from "../src/Images/JobOfferFinal.png";
import Navbar from "./components/Navbar";
export default function steps() {
  const images = [];
  const repeatCount = 2;
  const basePath = "./Images/Annimation/";
  const imageFileNames = [
    "1HomeDepot.webp",
    "2TTC.png",
    "3Walmart.webp",
    "4metro.png",
    "5Nexus.png",
    "6Shoppers.png",
    "7FarmBoy.png",
    "8Booster.png",
    "9Costco.webp",
    "10Sobeys.png",
    "11Subway.png",
    "12RealCanadian.png",
    "13Foodland.png",
    "14staffmax.png",
    "15FreshCo.png",
    "16FoodBasics.png",
    "17cleaning.webp",
  ];
  for (let i = 0; i < repeatCount; i++) {
    imageFileNames.forEach((fileName) => {
      images.push(require(`${basePath}${fileName}`));
    });
  }
  return (
    <div>
      <Navbar />
      <div className="process">
        <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
          <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
            <svg
              className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    className="text-gray-200"
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>
            <div className="relative">
              <h3 className="md:text-center text-left text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Job Placement in 4 Steps
              </h3>
              <p className="mx-auto mt-4 max-w-3xl md:text-center text-left text-xl leading-7 text-gray-500">
                At Dasatava, we pride ourselves on facilitating interviews for
                our customers through recruiters. Here's a breakdown of how our
                process works:
              </p>
            </div>
            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
              <div className="relative">
                <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                  Step 1: Submit Resume
                </h4>
                <p className="mt-3 text-lg leading-7 text-gray-700">
                  Upon Submission on your resume, Our team of experts will
                  conduct a thorough analysis to align your qualifications with
                  the available opportunities in our database. Your skills and
                  experiences will be carefully matched with the requirements of
                  various job openings
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-700">
                  Once avalability is matched then professionals will customise
                  your CV to highlight your experience and skills.
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-700">
                  Length of this process: Two days.
                </p>
              </div>
              <div className="relative -mx-4 mt-10 lg:mt-0">
                <svg
                  className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        className="text-gray-200"
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <img
                  className="relative mx-auto"
                  width="300"
                  src={ResumeImage}
                  alt="Resume Submission"
                  loading="auto"
                />
              </div>
            </div>
            <svg
              className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    className="text-gray-200"
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>
            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
                <div className="lg:col-start-2">
                  <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                    Step 2: Recruiters &amp; Hiring Managers
                  </h4>
                  <ul className="mt-10">
                    <li>
                      <div className="">
                        <p className="mt-2 text-base leading-6 text-gray-700">
                          Upon reviewing the your availability and CV, our
                          Coordinator team will promptly engage with recruiters
                          and hiring managers within our network.
                        </p>
                        <p className="mt-2 text-base leading-6 text-gray-700">
                          Recruiters will receive multiple resumes to evaluate.
                          Those from various organizations will analyze your
                          resume and then contact us to schedule an interview
                          you.
                        </p>
                        <p className="mt-2 text-base leading-6 text-gray-700">
                          Length of this process: 3-10 days*.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
                  <svg
                    className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          className="text-gray-200"
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    ></rect>
                  </svg>
                  <img
                    className="relative mx-auto"
                    width="300"
                    src={RecruitersImage}
                    alt="Recruiters at conference"
                    loading="auto"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-xl mt-50 px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
            <svg
              className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    className="text-gray-200"
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>
            <div className="relative mt-16 mb-8 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
              <div className="relative">
                <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                  Step 3: Interview Scheduled
                </h4>
                <p className="mt-3 text-lg leading-7 text-gray-700">
                  In this stage, we will promptly send you an email containing
                  details about the interview location, interview specifics,
                  role information, and valuable tips to help you pass in the
                  interview.
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-700">
                  You will receive the decision regarding your interview via
                  email once we receive feedback from the recruiters/hiring
                  managers.
                </p>
                <p className="mt-2 text-base leading-6 text-gray-700">
                  Length of this process : Decision of Recruiters
                </p>
              </div>
              <div className="relative -mx-4 mt-10 lg:mt-0">
                <svg
                  className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        className="text-gray-200"
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <img
                  className="relative mx-auto"
                  width="300"
                  src={InterviewImage}
                  alt="InterviewImage"
                  loading="auto"
                />
              </div>
            </div>
            <svg
              className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    className="text-gray-200"
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>
            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
                <div className="lg:col-start-2">
                  <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                    Step 4: Job Offer
                  </h4>
                  <ul className="mt-10">
                    <li>
                      <div className="">
                        <p className="mt-2 text-base leading-6 text-gray-700">
                          Once we receive positive feedback from the recruiters,
                          get ready for some exciting news! We'll be reaching
                          out to share it with you.
                        </p>
                        <p className="mt-2 text-base leading-6 text-gray-700">
                          Before we can send you start working, we kindly
                          request that you pay the remaining
                          <a
                            href="/PremiumService"
                            className="text-blue-600 hover:underline"
                          >
                            <strong>
                              {" "}
                              Post-Employment Premium Service Fee{" "}
                            </strong>
                          </a>
                          (Cost of your one-time Dasatva-provided (PPE) Personal
                          Protective Equipment).
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
                  <svg
                    className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          className="text-gray-200"
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    ></rect>
                  </svg>
                  <img
                    className="relative mx-auto"
                    width="300"
                    src={OfferImage}
                    alt="OfferImage"
                    loading="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
