import React from 'react'

export default function GetStarted() {
  return (
    <div className="pricepromo -mt-10 sm:mt-0">
    <div className="mx-auto max-w-screen-xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="xl:flex xl:items-center xl:justify-between">
        <div>
          <h1 className="text-4xl font-extrabold leading-10 sm:text-5xl sm:leading-none sm:tracking-tight">
            <span className="text-gray-900">Ready to get  </span>{" "}
            <span className="text-purple-700 text-opacity-90" id="b2b-price">
            Started
            </span>
            <span className="text-gray-900"> ? </span>{" "}
          </h1>
          <p className="mt-5 text-xl leading-7 text-gray-500">
          Our team is ready and waiting to get your first Dasatva shift posted. Prepare for a 21st century labor experience. <strong></strong>.
          </p>
        </div>
        <a
          className="focus:shadow-outline mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-purple-700 bg-opacity-90  px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-opacity-80 focus:outline-none sm:mt-10 sm:w-auto xl:mt-0"
          // href="RegistrationClosed"
          href="/GetStarted/"
        >
          Get Started
        </a>
      </div>
    </div>
  </div>
  )
}
