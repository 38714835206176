import React from 'react'
import Navbar from './Navbar';
import ResumeImage from "../Images/ResumeReviewOptional.png";
import RecruitersImage from "../Images/RecruiterReviewFinal.png";
import InterviewImage from "../Images/InterviewFinal.png";
import OfferImage from "../Images/JobOfferFinal.png";
import Footer from './Footer';

export default function About() {
  return (
    <>
    <Navbar/>
    <div className="process">
        <div className="overflow-hidden py-16 lg:py-24">
          <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
            
            <div className="relative">
              <h3 className="md:text-center text-left text-3xl font-extrabold leading-8 tracking-tight text-gray-600 sm:text-6xl sm:leading-10" style={{ fontFamily: "Inconsolata, monospace" }}>
              Our 4-Step Process 
              </h3>
              <p className="mx-auto mt-4 max-w-3xl md:text-center text-left text-xl leading-7 text-gray-500">
                At Dasatava, We remove as much of your work as possible
              </p>
            </div>
            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
              <div className="relative">
              <div class="icon-bg-96"><h3 class="hiw-step">1</h3></div>
                <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 mt-8 sm:text-3xl sm:leading-9 ">
                
                  Contact and Initial Setup
                </h4>
                <p className="mt-3 text-lg leading-7 text-gray-700">
                Once you reach out to us by filling out our contact form, we'll arrange a meeting to discuss your specific needs. This meeting helps us gain a clear understanding of your requirements and preferences.
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-700">
                We then create a detailed profile for your company. You’ll receive access to our web portal where you can track and manage your hiring needs.
                </p>
                
              </div>
              <div className="relative -mx-4 mt-10 lg:mt-0">
                
                <img
                  className="relative mx-auto"
                  width="300"
                  src={ResumeImage}
                  alt="Resume Submission"
                  loading="auto"
                />
              </div>
            </div>
           
            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
                <div className="lg:col-start-2">
                <div class="icon-bg-96"><h3 class="hiw-step">2</h3></div>
                  <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 mt-8 sm:text-3xl sm:leading-9">
                     Access Your Employer Portal
                  </h4>
                  <ul className="mt-10">
                    <li>
                      <div className="">
                        <p className="mt-2 text-base leading-6 text-gray-700">
                        After setting up your profile, we provide you with login credentials for our user-friendly employer portal. Here, you can view your company's profile and the contracts related to our services
                        </p>
                        <p className="mt-2 text-base leading-6 text-gray-700">
                        The portal is designed for ease of use, featuring options to communicate with our 24/7 support team and access a bot for submitting your worker requirements.
                        </p>
                        
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
                  
                  <img
                    className="relative mx-auto"
                    width="300"
                    src={RecruitersImage}
                    alt="Recruiters at conference"
                    loading="auto"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-xl mt-50 px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
            
            <div className="relative mt-16 mb-8 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
              <div className="relative">
              <div class="icon-bg-96"><h3 class="hiw-step">3</h3></div>
                <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 mt-8 sm:text-3xl sm:leading-9">
                   Manage and Report
                </h4>
                <p className="mt-3 text-lg leading-7 text-gray-700">
               Within the employer portal, you can use the chat feature to interact with our support team or submit any worker needs via the bot
                </p>
                <p className="mt-3 text-lg leading-7 text-gray-700">
                You also have the ability to report or request a replacement for any employee as needed. This ensures that your hiring process remains smooth and efficient.
                </p>
                
              </div>
              <div className="relative -mx-4 mt-10 lg:mt-0">
                
                <img
                  className="relative mx-auto"
                  width="300"
                  src={InterviewImage}
                  alt="InterviewImage"
                  loading="auto"
                />
              </div>
            </div>
            
            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
                <div className="lg:col-start-2">
                <div class="icon-bg-96"><h3 class="hiw-step">4</h3></div>
                  <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 mt-8 sm:text-3xl sm:leading-9">
                    Candidate Selection Process
                  </h4>
                  <ul className="mt-10">
                    <li>
                      <div className="">
                        <p className="mt-2 text-base leading-6 text-gray-700">
                        Once you post a job listing, our team meticulously searches for candidates who best match your job description
                        </p>
                        <p className="mt-2 text-base leading-6 text-gray-700">
                        We arrange up to three rounds of interviews with the selected candidates to ensure the best fit for your role. This thorough process helps you find the ideal employee for your company's needs.
                        </p>
                       
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
                  
                  <img
                    className="relative mx-auto"
                    width="300"
                    src={OfferImage}
                    alt="OfferImage"
                    loading="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}











