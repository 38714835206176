import React from "react";
import { useInView } from "react-intersection-observer";
import Reviews from "../Hire/Staffing/Reviews"
import OurIndustries from "../Hire/Staffing/OurIndustries";
import Navbar from "./NavbarEmp";
import Faqemp from "../Hire/FaqEmp"
import iphone from "../../../src/StaffingPhotos/iphone.png";
import GetStarted from "./GetStarted";
import Footer from "../Footer";
const FeatureItem = ({
  imgSrc,
  title,
  description,
  bgColor,
  animationClass,
}) => (
  <div
    className={`flex items-center pb-12 mb-12 border-b border-gray-300 ${animationClass}`}
  >
    <div
      className={`flex flex-shrink-0 w-15 h-15 mr-6 items-center justify-center ${bgColor} rounded-full`}
    >
      <img src={imgSrc} alt="" />
    </div>
    <div>
      <h3 className="text-xl font-semibold">{title}</h3>
      <span className="text-sm text-gray-400">{description}</span>
    </div>
  </div>
);

export default function Whyus() {

const { ref: sectionRef, inView: sectionInView } = useInView({
  triggerOnce: true,
  threshold: 0.7,
});

  return (
    <div>
      <Navbar/>

 {/* Iphone Section */}
 <section
          ref={sectionRef}
          className={`relative py-20  overflow-hidden transition-transform duration-1000 ${
            sectionInView
              ? "scale-105 translate-y-4"
              : "scale-100 translate-y-0"
          }`}
        >
          <div className="container px-4 mx-auto">
            <div className="max-w-3xl mx-auto text-center">
              <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">
                WHY US?
              </span>
              <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-24">
                <span>Upgrade your staffing to unlock all</span>
                <span className="font-serif italic">features</span>
              </h1>
            </div>
            <div className="max-w-7xl mx-auto">
              <div className="flex flex-wrap -mx-4 items-center">
                <div className="w-full lg:w-2/5 xl:w-auto px-4 lg:pb-10 mb-16 lg:mb-0">
                  <div className="mx-auto max-w-sm">
                    <FeatureItem
                      title="Best Price Commitment"
                      description="We Guarantee the Most Competitive Pricing.If You Find a Lower Rate Elsewhere, Our Services will be Free"
                      bgColor="bg-blue-100"
                      animationClass={
                        sectionInView
                          ? "zoom-out-left in-view"
                          : "zoom-out-left"
                      }
                    />
                    <FeatureItem
                      title="Dedicated Support"
                      description="Available and responsive within just 2 hours"
                      bgColor="bg-orange-100"
                      animationClass={
                        sectionInView
                          ? "zoom-out-left in-view"
                          : "zoom-out-left"
                      }
                    />
                    <FeatureItem
                      title="Employer Portal"
                      description="Commitment to high standards"
                      bgColor="bg-gray-300"
                      animationClass={
                        sectionInView
                          ? "zoom-out-left in-view"
                          : "zoom-out-left"
                      }
                    />
                  </div>
                </div>

                <div className="w-full lg:w-1/5 xl:w-2/5 mx-auto px-4 xl:-mr-10 mb-16 lg:mb-0">
                  <img
                    className={`block h-80 md:h-100 lg:h-auto xl:h-150 mx-auto zoom-in-up ${
                      sectionInView ? "in-view" : ""
                    }`}
                    src={iphone}
                    alt="iPhone"
                  />
                </div>
                <div className="w-full lg:w-2/5 xl:w-auto px-4 lg:pb-10">
                  <div className="mx-auto max-w-sm">
                    <FeatureItem
                      title="Extensive Database"
                      description="We have over 5000+ Skillfull candidates"
                      bgColor="bg-red-200"
                      animationClass={
                        sectionInView
                          ? "zoom-out-right in-view"
                          : "zoom-out-right"
                      }
                    />
                    <FeatureItem
                      title="In-Depth Screening"
                      description="We do 3 times Screening "
                      bgColor="bg-yellow-200"
                      animationClass={
                        sectionInView
                          ? "zoom-out-right in-view"
                          : "zoom-out-right"
                      }
                    />
                    <FeatureItem
                      title="Skilled Professionals"
                      description="We have dedicated specific skilled workers"
                      bgColor="bg-green-200"
                      animationClass={
                        sectionInView
                          ? "zoom-out-right in-view"
                          : "zoom-out-right"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Faqemp/>
        <Reviews/>
        <OurIndustries/>
        
        
    
   
    
      {/* Gallery section */}
      {/* <section className="py-10" id="gallery">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            Gallery
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src="https://image3.jdomni.in/banner/13062021/42/5C/B1/45AC18B7F8EE562BC3DDB95D34_1623559815667.png?output-format=webp"
                alt="Gallery . 1"
                className="w-full h-64 object-cover"
              />
            </div>

            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1606854428728-5fe3eea23475?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Z3JhbSUyMGZsb3VyfGVufDB8fDB8fHww"
                alt="Gallery . 2"
                className="w-full h-64 object-cover"
              />
            </div>

            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src="https://image2.jdomni.in/banner/13062021/D2/99/0D/48D7F4AFC48C041DC8D80432E9_1623562146900.png?output-format=webp"
                alt="Gallery . 3"
                className="w-full h-64 object-cover"
              />
            </div>

            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1607672632458-9eb56696346b?q=80&w=1914&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Gallery . 4"
                className="w-full h-64 object-cover"
              />
            </div>
          </div>
        </div>
      </section> */}

      <GetStarted/>
      <Footer/>
      
    </div>
  );
}
