import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaTelegram,
} from "react-icons/fa";

const sections = [
  {
    title: "EMPLOYERS",
    path: "employers",
    items: [
      { name: "Web Portal", path: "/Access" },
      { name: "Our Advantages ?", path: "/Whyus" },
      { name: "Our Process", path: "/About" },
      { name: "Get Started", path: "/Getstarted" },
    ],
  },
  {
    title: "JOB SEEKERS",
    path: "job-seekers",
    items: [
      { name: "Register", path: "/Register" },
      { name: "Browse Jobs", path: "/JobPostings" },
      { name: "Process", path: "/steps" },
      { name: "Job Status", path: "/JobPostings" },
    ],
  },
  {
    title: "SERVICES",
    path: "services",
    items: [
      { name: "Temporary Staffing", path: "/TemporaryStaffing" },
      { name: "Direct Hire", path: "/DirectHire" },
      { name: "Contract to Hire", path: "/ContractHire" },
      { name: "Payrolling", path: "/Payrolling" },
    ],
  },
  {
    title: "CONTACT US",
    path: "contact-us",
    items: [
      { name: "info@dasatva.com", path: "mailto:info@dasatva.com" },
      { name: "Live Chat", path: "/contact-us/live-chat" },
      { name: "Get Started", path: "/GetStarted" },
    ],
  },
];

const items = [
  { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/login/device-based/regular/login/?login_attempt=1" },
  { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/dasatva.interviews?igsh=cHY3OGViN3J1dDU1" },
  { name: "Twitter", icon: FaTwitter, link: "https://twitter.com/" },
  { name: "Linkedin", icon: FaLinkedin, link: "https://www.linkedin.com/company/dasatva-interviews/" }, 
  { name: "Telegram", icon: FaTelegram, link: "https://t.me/DasatvaInterviews_bot" },
];

const Footer = () => {
  return (
    <div className="w-full mt-24 bg-slate-900 text-gray-300 py-y px-2">
      <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8">
        {sections.map((section, index) => (
          <div key={index}>
            <a href={`/${section.path}`} className="font-bold uppercase pt-2">
              {section.title}
            </a>
            <ul>
              {section.items.map((item, i) => (
                <li key={i} className="py-1 text-gray-500 hover:text-white">
                  <a href={item.path}>
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}

        <div className="col-span-2 pt-8 md:pt-2">
          <p className="font-bold uppercase">Subscribe to our newsletter</p>
          <p className="py-4">
            The latest news, articles, and resources, sent to your inbox weekly.
          </p>
          <form className="flex flex-col sm:flex-row">
            <input
              className="w-full p-2 mr-4 rounded-md mb-4"
              type="email"
              placeholder="Enter email.."
            />
            <button className="p-2 mb-4">Subscribe</button>
          </form>
        </div>
      </div>

      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">&copy; 2024 Dasatava-Interviews</p>
        <p className="py-4">info@dasatva.com</p>
        <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
          {items.map((item, index) => (
            <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">
              <item.icon className="hover:text-white" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
