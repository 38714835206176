import React from 'react';
import './ImagePopup.css';
import yourImage from '../Images/Nadachadi.png'; // replace with your image path
import aksharpurshottam from "../Images/002.png"

function ImagePopup({ onClose }) {
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content">
        
        <img src={aksharpurshottam} alt="Popup" />
        <img src={yourImage} alt="Popup" />
      </div>
    </div>
  );
}

export default ImagePopup;
