import React from "react";
import Navbar from "./components/Navbar"
import MainPageTR from "./Images/MainPage-TR3.avif"; // Import the image file
import "./CSS/Home.css";


function Home() {
  
  return (
    <>
       <Navbar transparent />
    <div className="Homepage">
      <div className="startedSection">
        <div className="relative overflow-hidden bg-white sm:-pt-20 lg:-pt-24">
          <div className="mx-auto max-w-screen-xl">
            <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
              <svg
                className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-white lg:block"
                fill="currentColor"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
              >
                <polygon
                  points="50,0 100,0 50,100 0,100"
                  className=""
                ></polygon>
              </svg>
              <main className="mx-auto pt-10 max-w-screen-xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
                <div className="sm:text-center lg:text-left">
                  <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none custom-class">
                    <span className="mr-1">
                      Unlock your career potential Today{" "}
                    </span>
                    <span class="text-purple-700 opacity-90">
                      Job Interviews &amp; Opportunities awaits!
                    </span>
                  </h2>
                  <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                  Unlock your true career potential with us! As a leading staffing company, we specialize in connecting you with job opportunities that align with your skills and aspirations. Our services are designed to simplify your job search and help you find the perfect fit. Trust us to support your career journey with our seamless and cost-free service. Discover why so many employees rely on us to advance their careers.
                  </p>
                  <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                    That's why{" "}
                    <span id="hero-region-phrase">
                      <strong> People </strong>
                    </span>{" "}
                    Trust us
                  </p>
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                      <a
                        className="focus:shadow-outline-purple flex w-full items-center justify-center rounded-md border border-transparent bg-purple-700 bg-opacity-90 px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-opacity-80 focus:border-purple-700 focus:outline-none md:py-4 md:px-10 md:text-lg"
                        href="/Registration"
                        // href="/RegistrationClosed"
                      >
                        Register Now
                      </a>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a
                        className="focus:shadow-outline-indigo flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-8 py-3 text-base font-medium leading-6 text-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-50 hover:text-indigo-600 focus:border-indigo-300 focus:outline-none md:py-4 md:px-10 md:text-lg"
                        href="/steps/"
                      >
                        Steps
                      </a>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
              src={MainPageTR}
              loading="lazy"
              alt="Marko and Dejan coding"
              // Adjust the value as needed to move the image to the right
            />
          </div>
        </div>
      </div>

    

     
     
      
    </div>
    </>
  );
}

export default Home;
