import React from 'react';

function ImageGallery({ imagePaths }) {
  return (
    <div className="flex space-x-16  mt-14 mb-20  animate-loop-scroll group-hover:paused" aria-hidden="true">
      {imagePaths.map((imagePath, index) => (
        // eslint-disable-next-line 
        <img
        key={index}
        loading="lazy"
        src={imagePath}
        className="max-w-full max-h-24 p-2 grayscale"
        alt={`Image ${index + 1}`}
        style={{transition: "filter 0.3s ease"}}
      />
    ))}
    {/* CSS for grayscale and hover effects */}
    <style>
      {`
        /* Apply grayscale filter */
        .grayscale {
          filter: grayscale(10%);
          opacity: 0.5;
          transition: filter 0.5s ease-in-out, opacity 0.5s ease-in-out;
        }
      
        /* Remove grayscale filter and change opacity on hover */
        .grayscale:hover {
          filter: grayscale(0%);
          opacity: 0.9;
        }
      `}
    </style>
  </div>
);
}

export default ImageGallery;