import React, { useState, useEffect } from "react";
import image1 from "../../../StaffingPhotos/detailstaffing/office.webp";
import Navbar from "../NavbarEmp";
import partner1 from "../../../Images/partners/purolator.png";
import partner2 from "../../../Images/partners/1800gotjunk.png";
import partner3 from "../../../Images/partners/Cargojet.png";
import partner4 from "../../../Images/partners/coremark.png";
import partner5 from "../../../Images/partners/hardbite.png";
import OurIndustries from "../Staffing/OurIndustries";
import GetStarted from "../GetStarted";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import Footer from "../../Footer";
export default function Offices() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = image1;
    img.onload = () => setLoading(false);
    img.onerror = (err) => {
      console.error("Image loading failed", err);
      setLoading(false); // Set loading to false even if loading fails
    };
  }, []);

  return (
    <>
      <Navbar />

      <div className="w-full relative mb-12" style={{ height: "70vh" }}>
        {loading ? (
          <div
            className="flex justify-center items-center"
            style={{ height: "70vh" }}
          >
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full"></div>
          </div>
        ) : (
          <div
            className="bg-indigo-50 rounded-2xl flex justify-center items-center"
            style={{ height: "70vh" }}
          >
            <img
              src={image1}
              alt="Direct Placement"
              className="w-full object-cover rounded-2xl"
              style={{ height: "70vh" }}
            />
          </div>
        )}

        {/* Text Container */}
        <div className="absolute inset-0 flex items-center justify-center text-center">
          <div className="text-white px-4">
            <h1 className="font-semibold text-6xl md:text-8xl">Office</h1>
            <p className="mt-4 text-xl text-white-200">
              Finding the Right Talent for Your Business
            </p>
          </div>
        </div>
      </div>
      {/* expertise */}
      <div className="relative bg-white py-16 sm:py-24 lg:py-16">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Staffing Solutions for Key Office Roles
          </p>
          <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
            Comprehensive Staffing Solutions for the Office Sector: Our
            Expertise in Key Roles
          </p>
          <div className="mt-12 text-left">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-purple-400 p-3 shadow-lg">
                        <svg
                          className="h-6 w-6 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokelinecap="round"
                            strokelinejoin="round"
                            strokewidth="2"
                            d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                      Office Administration and Support
                    </h3>
                    <p className="mt-5 text-base text-gray-500"></p>
                    <ul className="mt-2 list-disc pl-4 text-base leading-6 text-gray-500">
                      <li> Administrative Assistant</li>
                      <li>Executive Assistant</li>
                      <li>Office Manager</li>
                      <li> Receptionist</li>
                      <li>Data Entry Clerk</li>
                    </ul>
                    <p></p>
                  </div>
                </div>
              </div>

              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-purple-400 p-3 shadow-lg">
                      <FontAwesomeIcon icon={faBriefcase} className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                      Human Resources Roles
                    </h3>
                    <p className="mt-5 text-base text-gray-500"></p>
                    <ul className="mt-2 list-disc pl-4 text-base leading-6 text-gray-500">
                      <li> HR Specialist </li>
                      <li>HR Manager </li>
                      <li>Recruiter </li>
                      <li>Payroll Administrator</li>
                      <li>Training Coordinator </li>
                    </ul>
                    <p></p>
                  </div>
                </div>
              </div>

              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-purple-400 p-3 shadow-lg">
                        <svg
                          className="h-6 w-6 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokelinecap="round"
                            strokelinejoin="round"
                            strokewidth="2"
                            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                      Finance and Accounting
                    </h3>
                    <p className="mt-5 text-base text-gray-500"></p>
                    <ul className="mt-2 list-disc pl-4 text-base leading-6 text-gray-500">
                      <li>Accountant </li>
                      <li>Financial Analyst </li>
                      <li>Controller </li>
                      <li>Accounts Payable Clerk </li>
                      <li>Accounts Receivable Clerk </li>
                    </ul>
                    <p></p>
                  </div>
                </div>
              </div>

              <div className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-purple-400 p-3 shadow-lg">
                      <FontAwesomeIcon icon={faArrowTrendUp} className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                    Marketing and Sales
                    </h3>
                    <p className="mt-5 text-base text-gray-500"></p>
                    <ul className="mt-2 list-disc pl-4 text-base leading-6 text-gray-500">
                      <li> Sales Representative </li>
                      <li> Content Creator </li>
                      <li>Digital Marketing Specialist </li>
                      <li>Sales Manager </li>
                      <li>Other</li>
                    </ul>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Included */}
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-12 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Unlock Exclusive <span id="acc-dev-price"> Benefits</span> When You
            Choose Us
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            Choosing us unlocks access to exclusive, customizable benefits.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8">
          <div className="flex">
            <svg
              className="h-6 w-6 flex-shrink-0 rounded bg-purple-500 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokelinecap="round"
                strokelinejoin="round"
                strokewidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <div className="ml-3">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                WSIB
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Unlock exclusive, customizable WSIB premium solutions that
                optimize costs and ensure comprehensive coverage for your
                business.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="h-6 w-6 flex-shrink-0 rounded bg-purple-500 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokelinecap="round"
                strokelinejoin="round"
                strokewidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <div className="ml-3">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Holiday Pay
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                We cover government holiday pay for employees without passing
                the cost on to our clients.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="h-6 w-6 flex-shrink-0 rounded bg-purple-500 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokelinecap="round"
                strokelinejoin="round"
                strokewidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <div className="ml-3">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                24/7 Support
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Receive around-the-clock support to address any issues or
                questions promptly.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="h-6 w-6 flex-shrink-0 rounded bg-purple-500 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokelinecap="round"
                strokelinejoin="round"
                strokewidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <div className="ml-3">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                No Additional Charges
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                No additional charges—just the lowest price for staffing agency
                solutions.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="h-6 w-6 flex-shrink-0 rounded bg-purple-500 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokelinecap="round"
                strokelinejoin="round"
                strokewidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <div className="ml-3">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Professional Liability
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                We include professional liability coverage to protect your
                business and ensure peace of mind.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="h-6 w-6 flex-shrink-0 rounded bg-purple-500 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokelinecap="round"
                strokelinejoin="round"
                strokewidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <div className="ml-3">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Umbrella Liability
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                We provide umbrella liability coverage to offer an extra layer
                of protection beyond standard policies.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="h-6 w-6 flex-shrink-0 rounded bg-purple-500 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokelinecap="round"
                strokelinejoin="round"
                strokewidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <div className="ml-3">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                5000+ Candidates
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                With a database of over 5000+ candidates, we find the perfect
                match for your needs.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="h-6 w-6 flex-shrink-0 rounded bg-purple-500 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokelinecap="round"
                strokelinejoin="round"
                strokewidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <div className="ml-3">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Advanced Screening
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Our advanced screening process ensures you get highly qualified
                candidates with thorough background checks and evaluations.
              </dd>
            </div>
          </div>
        </dl>
      </div>
      {/* Ourpartners */}
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">BUSINESSES THAT TRUST US</span>
        </h2>

        <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
          Trusted by these bussiness and more
        </p>

        <div className="bg-white">
          <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src={partner1} alt="CBS" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src={partner2} alt="FOX" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src={partner3} alt="NBC" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-12" src={partner4} alt="USA Today" />
              </div>
              <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                <img className="h-12" src={partner5} alt="NCN" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetStarted />
      <OurIndustries />
      <Footer />
    </>
  );
}
