import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage"; // Import Firebase Storage

var firebaseConfig = {
  apiKey: "AIzaSyDNG23pKA-kPvPOutgd_YzXZCfQf3KPRMg",
  authDomain: "dvervr3.firebaseapp.com",
  projectId: "dvervr3",
  storageBucket: "dvervr3.appspot.com",
  messagingSenderId: "655926139523",
  appId: "1:655926139523:web:a94103f3892f9f671d19b6",
  measurementId: "G-P12TEH85YK"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const fireDb = firebaseApp.database().ref();
const fireStorage = firebaseApp.storage().ref(); // Initialize Firebase Storage

export { fireDb, fireStorage };
