import React from "react";
import gradient from "../..//../Images/gradient3.svg";

export default function Reviews() {
  return (
    <div>
    <section
  data-section-id="1"
  data-share=""
  data-category="testimonials"
  data-component-id="14186a75_04_awz"
  className="relative pt-24 pb-32 bg-white overflow-hidden bg-gray-50 -mt-20 sm:mt-0"
  data-path="0"
>


        <img
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          src={gradient}
          alt=""
          data-config-id="auto-img-1-4"
          data-path="0.0"
        />
        <div className="relative z-10 container px-4 mx-auto " data-path="0.1">
          <div
            className="flex flex-wrap justify-between items-end -m-2 mb-12"
            data-path="0.1.0"
          >
            <div className="w-auto p-2 " data-path="0.1.0.0">
              <h2
                className="text-3xl  md:text-7xl font-bold font-heading tracking-px-n leading-tight"
                data-config-id="auto-txt-1-4"
                data-path="0.1.0.0.0"
              >
                Clients love using Dasatva.
              </h2>
            </div>

            <div className="w-auto md:p-2" data-path="0.1.0.1">
              <a
                className="hidden md:block font-sans md:font-medium text-indigo-600 hover:text-indigo-700 leading-10"
                href="/Soon/"
                data-config-id="auto-txt-2-4"
                data-path="0.1.0.1.0"
              >
                See all our reviews
              </a>
            </div>
          </div>
          <div className="flex flex-wrap -m-2" data-path="0.1.1">
            <div className="w-full md:w-1/2 lg:w-1/4 p-2" data-path="0.1.1.0">
              <div
                className="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl"
                data-path="0.1.1.0.0"
              >
                <div
                  className="flex flex-col justify-between h-full"
                  data-path="0.1.1.0.0.0"
                >
                  <div className="mb-7 block" data-path="0.1.1.0.0.0.0">
                    <div
                      className="flex flex-wrap -m-0.5 mb-6"
                      data-path="0.1.1.0.0.0.0.0"
                    >
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.0.0.0.0.0.0"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-1-4"
                          data-path="0.1.1.0.0.0.0.0.0.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.0.0.0.0.0.1"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-2-4"
                          data-path="0.1.1.0.0.0.0.0.1.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.0.0.0.0.0.2"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-3-4"
                          data-path="0.1.1.0.0.0.0.0.2.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.0.0.0.0.0.3"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-4-4"
                          data-path="0.1.1.0.0.0.0.0.3.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.0.0.0.0.0.4"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-5-4"
                          data-path="0.1.1.0.0.0.0.0.4.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <h3
                      className="mb-6 text-lg font-bold font-heading"
                      data-config-id="auto-txt-3-4"
                      data-path="0.1.1.0.0.0.0.1"
                    >
                      “Geniune”
                    </h3>
                    <p
                      className="text-lg font-medium"
                      data-config-id="auto-txt-4-4"
                      data-path="0.1.1.0.0.0.0.2"
                    >
                      I highly recommend Dasatva Staffing for all your staffing
                      needs. Their team is professional, and it has been a
                      delight collaborating with them for our personnel
                      requirements
                    </p>
                  </div>
                  <div className="block" data-path="0.1.1.0.0.0.1">
                    <p
                      className="font-bold"
                      data-config-id="auto-txt-5-4"
                      data-path="0.1.1.0.0.0.1.0"
                    >
                      Simranpreet Kaur - Supervisor{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 p-2" data-path="0.1.1.1">
              <div
                className="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl"
                data-path="0.1.1.1.0"
              >
                <div
                  className="flex flex-col justify-between h-full"
                  data-path="0.1.1.1.0.0"
                >
                  <div className="mb-7 block" data-path="0.1.1.1.0.0.0">
                    <div
                      className="flex flex-wrap -m-0.5 mb-6"
                      data-path="0.1.1.1.0.0.0.0"
                    >
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.1.0.0.0.0.0"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-6-4"
                          data-path="0.1.1.1.0.0.0.0.0.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.1.0.0.0.0.1"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-7-4"
                          data-path="0.1.1.1.0.0.0.0.1.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.1.0.0.0.0.2"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-8-4"
                          data-path="0.1.1.1.0.0.0.0.2.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.1.0.0.0.0.3"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-9-4"
                          data-path="0.1.1.1.0.0.0.0.3.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.1.0.0.0.0.4"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-10-4"
                          data-path="0.1.1.1.0.0.0.0.4.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <h3
                      className="mb-6 text-lg font-bold font-heading"
                      data-config-id="auto-txt-6-4"
                      data-path="0.1.1.1.0.0.0.1"
                    >
                      “Good Price”
                    </h3>
                    <p
                      className="text-lg font-medium"
                      data-config-id="auto-txt-7-4"
                      data-path="0.1.1.1.0.0.0.2"
                    >
                      Our team had been working with a staffing agency for
                      nearly four years and had no plans to switch, we realized
                      that choosing dasatva could save us a significant amount
                      of money. We decided to make the switch
                    </p>
                  </div>
                  <div className="block" data-path="0.1.1.1.0.0.1">
                    <p
                      className="font-bold"
                      data-config-id="auto-txt-8-4"
                      data-path="0.1.1.1.0.0.1.0"
                    >
                      Doung.K - Production Manager
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 p-2" data-path="0.1.1.2">
              <div
                className="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl"
                data-path="0.1.1.2.0"
              >
                <div
                  className="flex flex-col justify-between h-full"
                  data-path="0.1.1.2.0.0"
                >
                  <div className="mb-7 block" data-path="0.1.1.2.0.0.0">
                    <div
                      className="flex flex-wrap -m-0.5 mb-6"
                      data-path="0.1.1.2.0.0.0.0"
                    >
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.2.0.0.0.0.0"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-11-4"
                          data-path="0.1.1.2.0.0.0.0.0.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.2.0.0.0.0.1"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-12-4"
                          data-path="0.1.1.2.0.0.0.0.1.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.2.0.0.0.0.2"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-13-4"
                          data-path="0.1.1.2.0.0.0.0.2.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.2.0.0.0.0.3"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-14-4"
                          data-path="0.1.1.2.0.0.0.0.3.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.2.0.0.0.0.4"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-15-4"
                          data-path="0.1.1.2.0.0.0.0.4.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <h3
                      className="mb-6 text-lg font-bold font-heading"
                      data-config-id="auto-txt-9-4"
                      data-path="0.1.1.2.0.0.0.1"
                    >
                      “Zero Absence”
                    </h3>
                    <p
                      className="text-lg font-medium"
                      data-config-id="auto-txt-10-4"
                      data-path="0.1.1.2.0.0.0.2"
                    >
                      We needed a forklift operator, and Dasatva provided us
                      with a candidate who hasn't taken a single day off in the
                      past nine months.
                    </p>
                  </div>
                  <div className="block" data-path="0.1.1.2.0.0.1">
                    <p
                      className="font-bold"
                      data-config-id="auto-txt-11-4"
                      data-path="0.1.1.2.0.0.1.0"
                    >
                      Agatha HO - Team Lead
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 p-2" data-path="0.1.1.3">
              <div
                className="px-8 py-6 h-full bg-white bg-opacity-80 rounded-3xl"
                data-path="0.1.1.3.0"
              >
                <div
                  className="flex flex-col justify-between h-full"
                  data-path="0.1.1.3.0.0"
                >
                  <div className="mb-7 block" data-path="0.1.1.3.0.0.0">
                    <div
                      className="flex flex-wrap -m-0.5 mb-6"
                      data-path="0.1.1.3.0.0.0.0"
                    >
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.3.0.0.0.0.0"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-16-4"
                          data-path="0.1.1.3.0.0.0.0.0.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.3.0.0.0.0.1"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-17-4"
                          data-path="0.1.1.3.0.0.0.0.1.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.3.0.0.0.0.2"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-18-4"
                          data-path="0.1.1.3.0.0.0.0.2.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.3.0.0.0.0.3"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-19-4"
                          data-path="0.1.1.3.0.0.0.0.3.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className="w-auto p-0.5"
                        data-path="0.1.1.3.0.0.0.0.4"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-config-id="auto-svg-20-4"
                          data-path="0.1.1.3.0.0.0.0.4.0"
                        >
                          <path
                            d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z"
                            fill="#F59E0B"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <h3
                      className="mb-6 text-lg font-bold font-heading"
                      data-config-id="auto-txt-12-4"
                      data-path="0.1.1.3.0.0.0.1"
                    >
                      “WebPortal & Support”
                    </h3>
                    <p
                      className="text-lg font-medium"
                      data-config-id="auto-txt-13-4"
                      data-path="0.1.1.3.0.0.0.2"
                    >
                      I've never seen such rapid response times of 1-2 hours.
                      Their employer portal makes it easy to request the exact
                      number of workers we need, whether it's 2,4 or 10
                    </p>
                  </div>
                  <div className="block" data-path="0.1.1.3.0.0.1">
                    <p
                      className="font-bold"
                      data-config-id="auto-txt-14-4"
                      data-path="0.1.1.3.0.0.1.0"
                    >
                      Harman Sindhu - Production Lead
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
