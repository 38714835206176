import React from 'react'
import warehouseimage from "../../../../src/StaffingPhotos/warehouse.jpg";
import Constructionimage from "../../../../src/StaffingPhotos/construction4.jpg";
import manufacturingimage from "../../../../src/StaffingPhotos/manufacturing.jpg";
import transportimage from "../../../../src/StaffingPhotos/transport.jpg";
import hotelimage from "../../../../src/StaffingPhotos/hotelstaff2.jpg";
import officeimage from "../../../../src/StaffingPhotos/office.webp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
export default function OurIndustries() {
  return (
    <div>
 <section className="pb-24 pt-8" id="services">
          <div className="container mx-auto px-4">
            <h2
              className="text-3xl text-gray-600 mb-8 text-center"
              style={{ fontFamily: "Inconsolata, monospace" }}
            >
              Our Industries
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <a href="/Warehousing" aria-label="Home" className="block">
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:bg-purple-100 group">
                  <img
                    src={warehouseimage}
                    alt="Hotel"
                    className="w-full h-64 object-cover transition-transform duration-[2000ms] ease-in-out transform group-hover:scale-[1.1]"
                  />
                  <div className="p-6 text-center">
                    <h2
                      className="text-xl font-medium text-gray-800 mb-8 mt-2"
                      style={{ fontFamily: "Inconsolata, monospace" }}
                    >
                      Warehousing
                    </h2>
                    <p className="text-gray-700 text-base">
                    
We provide experienced and reliable warehouse staff to optimize your operations. From inventory management and order picking to equipment operation, our team ensures your warehouse functions efficiently, meeting all your logistical needs.
                    </p>
                    <div className="pt-4 flex justify-end">
                      <details>
                        <summary className="cursor-pointer flex items-center text-[#0d6efd]">
                          <a
                            href="/Warehousing"
                            aria-label="Home"
                            className="cursor-pointer text-[#0d6efd] flex items-center"
                          >
                            View More
                            <KeyboardArrowRightIcon className="ml-1" />
                          </a>
                        </summary>
                      </details>
                    </div>
                  </div>
                </div>
              </a>

              <a href="/Manufacturing" aria-label="Home" className="block">
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:bg-purple-100 group">
                  <img
                    src={manufacturingimage}
                    alt="Hotel"
                    className="w-full h-64 object-cover transition-transform duration-[2000ms] ease-in-out transform group-hover:scale-[1.1]"
                  />
                  <div className="p-6 text-center">
                    <h2
                      className="text-xl font-medium text-gray-800 mb-8 mt-2"
                      style={{ fontFamily: "Inconsolata, monospace" }}
                    >
                      Manufacturing
                    </h2>
                    <p className="text-gray-700 text-base">
                    Count on us to provide skilled and dependable workers for your manufacturing and food production needs. From assembly line tasks to quality control and packaging, our team ensures your operations run smoothly and efficiently.
                    </p>
                    <div className="pt-4 flex justify-end">
                      <details>
                        <summary className="cursor-pointer flex items-center text-[#0d6efd]">
                          <a
                            href="/Manufacturing"
                            aria-label="Home"
                            className="cursor-pointer text-[#0d6efd] flex items-center"
                          >
                            View More
                            <KeyboardArrowRightIcon className="ml-1" />
                          </a>
                        </summary>
                      </details>
                    </div>
                  </div>
                </div>
              </a>

              <a href="/Hospitality" aria-label="Home" className="block">
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:bg-purple-100 group">
                  <img
                    src={hotelimage}
                    alt="Hotel"
                    className="w-full h-64 object-cover transition-transform duration-[2000ms] ease-in-out transform group-hover:scale-[1.1]"
                  />

                  <div className="p-6 text-center">
                    <h2
                      className="text-xl font-medium text-gray-800 mb-8 mt-2"
                      style={{ fontFamily: "Inconsolata, monospace" }}
                    >
                      Hospitality & Catering
                    </h2>
                    <p className="text-gray-700 text-base">
                    Rely on us to supply experienced and professional staff for your hospitality and catering needs. From front-of-house service to event management and culinary support, our team is committed to delivering exceptional service for your guests
                    </p>
                    <div className="pt-4 flex justify-end">
                      <details>
                        <summary className="cursor-pointer flex items-center text-[#0d6efd]">
                          <a
                            href="/Hospitality"
                            aria-label="Home"
                            className="cursor-pointer text-[#0d6efd] flex items-center"
                          >
                            View More
                            <KeyboardArrowRightIcon className="ml-1" />
                          </a>
                        </summary>
                      </details>
                    </div>
                  </div>
                </div>
              </a>

              <a href="/Construction" aria-label="Home" className="block">
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:bg-purple-100 group">
                  <img
                    src={Constructionimage}
                    alt="Hotel"
                    className="w-full h-64 object-cover transition-transform duration-[2000ms] ease-in-out transform group-hover:scale-[1.1]"
                  />
                  <div className="p-6 text-center">
                    <h2
                      className="text-xl font-medium text-gray-800 mb-8 mt-2"
                      style={{ fontFamily: "Inconsolata, monospace" }}
                    >
                      Construction
                    </h2>
                    <p className="text-gray-700 text-base">
                    Trust us to provide skilled and reliable workers for your construction projects. From general labor and skilled trades to site management, our team ensures your construction site operates efficiently and meets project deadlines.
                    </p>
                    <div className="pt-4 flex justify-end">
                      <details>
                        <summary className="cursor-pointer flex items-center text-[#0d6efd]">
                          <a
                            href="/Construction"
                            aria-label="Home"
                            className="cursor-pointer text-[#0d6efd] flex items-center"
                          >
                            View More
                            <KeyboardArrowRightIcon className="ml-1" />
                          </a>
                        </summary>
                      </details>
                    </div>
                  </div>
                </div>
              </a>

              <a href="/Office" aria-label="Home" className="block">
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:bg-purple-100 group">
                  <img
                    src={officeimage}
                    alt="Hotel"
                    className="w-full h-64 object-cover transition-transform duration-[2000ms] ease-in-out transform group-hover:scale-[1.1]"
                  />
                  <div className="p-6 text-center">
                    <h2
                      className="text-xl font-medium text-gray-800 mb-8 mt-2"
                      style={{ fontFamily: "Inconsolata, monospace" }}
                    >
                      Office
                    </h2>
                    <p className="text-gray-700 text-base">
                    Depend on us to supply experienced and reliable professionals for your office roles. From administrative support to HR, finance, and management, our team ensures your business operations run smoothly and efficiently
                    </p>
                    <div className="pt-4 flex justify-end">
                      <details>
                        <summary className="cursor-pointer flex items-center text-[#0d6efd]">
                          <a
                            href="/Office"
                            aria-label="Home"
                            className="cursor-pointer text-[#0d6efd] flex items-center"
                          >
                            View More
                            <KeyboardArrowRightIcon className="ml-1" />
                          </a>
                        </summary>
                      </details>
                    </div>
                  </div>
                </div>
              </a>

              <a href="/Logistics" aria-label="Home" className="block">
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:bg-purple-100 group">
                  <img
                    src={transportimage}
                    alt="Hotel"
                    className="w-full h-64 object-cover transition-transform duration-[1500ms] ease-in-out transform group-hover:scale-[1.1]"
                  />
                  <div className="p-6 text-center">
                    <h2
                      className="text-xl font-medium text-gray-800 mb-8 mt-2"
                      style={{ fontFamily: "Inconsolata, monospace" }}
                    >
                      Logistics & Transport
                    </h2>
                    <p className="text-gray-700 text-base">
                    Our skilled personnel keep your logistics and transportation operations running smoothly. From drivers and dispatchers to warehouse support and fleet management, we ensure your supply chain stays efficient and on schedule
                    </p>
                    <div className="pt-4 flex justify-end">
                      <details>
                        <summary className="cursor-pointer flex items-center text-[#0d6efd]">
                          <a
                            href="/Logistics"
                            aria-label="Home"
                            className="cursor-pointer text-[#0d6efd] flex items-center"
                          >
                            View More
                            <KeyboardArrowRightIcon className="ml-1" />
                          </a>
                        </summary>
                      </details>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>


    </div>
  )
}
