import React, { useEffect } from "react";
import Logo from "../Images/logo5122.png";



function Navbar() {
  function toggleMobileMenu() {
    var menu = document.getElementById("main-mobile-menu");
    if (menu.className.includes("hidden")) {
      menu.classList.remove("hidden");
    } else {
      menu.classList.add("hidden");
    }
  }
  
  function toggleServicesMenu() {
    var submenu = document.getElementById("solutions-submenu");
    submenu.classList.toggle("hidden");
  }

  function toggleServicesMenuduplicate() {
    var submenu = document.getElementById("solutions-duplicatemenu");
    submenu.classList.toggle("hidden");
  }

  useEffect(() => {
    // Close the submenu when the component mounts
    const submenu = document.getElementById("solutions-submenu");
    if (submenu) {
      submenu.classList.add("hidden");
    }
  }, []);
  
  useEffect(() => {
    const submenu = document.getElementById("solutions-duplicatemenu");
    if (submenu) {
      submenu.classList.add("hidden");
    }
  }, []);

  return (
    <div className="navbar">
    <div className="relative  pt-6 pb-0 sm:pb-4">
      <div className="mx-auto max-w-screen-xxl px-4 sm:px-6">
        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
          <div className="flex flex-1 items-center md:absolute md:inset-y-0 md:left-0">
            <div className="flex w-full items-center justify-between md:w-auto">
              <a href="/" aria-label="Home">
                <img
                  className="md:h-32 md:w-40 lg:h-36 lg:w-52 h-28 w-32"
                  src={Logo}
                  alt="Dasatava"
                />
              </a>
              <div className="-mr-2 flex items-center md:hidden">
                <a
                  href="/Access/"
                  className="bg-purple-600 bg-opacity-90 hover:bg-opacity-80 text-white block w-full bg-gray-50 px-5 py-3 text-center font-medium transition duration-150 ease-in-out hover:bg-gray-100 hover:text-indigo-700 focus:bg-gray-100 focus:text-indigo-700 focus:outline-none"
                >
                  Sign in
                </a>

                <button
                  className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
                  id="main-menu"
                  type="button"
                  aria-label="Main menu"
                  aria-haspopup="true"
                  onClick={toggleMobileMenu}
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:flex md:space-x-2">
            <div className="">
              <a
                href="/"
                className="block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-500"
              >
                Employers
              </a>
            </div>
            <div className="">
              <div className="relative ">
                <button
                  type="button"
                  // className="block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-500"
                  className="mx-1 block inline-flex items-center rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-500"
                  aria-expanded="false"
                  onClick={toggleServicesMenu}
                >
                  <span>Services</span>
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>

                <div
                  id="solutions-submenu"
                  className="absolute left-1/2 z-50 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4"
                >
                  <div className="w-screen max-w-md flex-auto overflow-hidden rounded-xl text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 backdrop-blur-xl bg-white bg-opacity-40">
                    <div className="p-4">
                      <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                        <div>
                          <a
                            href="/TemporaryStaffing/"
                            className="font-semibold text-gray-900"
                          >
                            Temporary Staffing
                            <span className="absolute inset-0"></span>
                          </a>
                          <p className="mt-1 text-gray-600">
                            Get Staffing made simple
                          </p>
                        </div>
                      </div>

                      <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                        <div>
                          <a
                            href="/DirectHire/"
                            className="font-semibold text-gray-900"
                          >
                            Direct Hire
                            <span className="absolute inset-0"></span>
                          </a>
                          <p className="mt-1 text-gray-600">
                          Finding the Right Talent for Your Business
                          </p>
                        </div>
                      </div>

                      <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                        <div>
                          <a
                            href="/ContractHire/"
                            className="font-semibold text-gray-900"
                          >
                            Contract to Hire
                            <span className="absolute inset-0"></span>
                          </a>
                          <p className="mt-1 text-gray-600">
                          Acquire top talent—when you need it.
                          </p>
                        </div>
                      </div>
                      <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                        <div>
                          <a
                            href="/Payrolling/"
                            className="font-semibold text-gray-900"
                          >
                            Payrolling
                            <span className="absolute inset-0"></span>
                          </a>
                          <p className="mt-1 text-gray-600">
                          Outsourced, reliable payroll
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <a
                href="/Whyus/"
                className="block rounded-lg  py-2.5 text-base font-semibold leading-7 text-gray-500"
                style={{ marginRight: "20px" }}
              >
                Why us?
              </a>
            </div>

            <div className="">
              <div className="relative ">
                <button
                  type="button"
                  // className="block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-500"
                  className="mx-1 block inline-flex items-center rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-500"
                  aria-expanded="false"
                  onClick={toggleServicesMenuduplicate}
                >
                  <span>Job Seekers</span>
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>

                <div
                  id="solutions-duplicatemenu"
                  className="absolute left-1/2 z-50 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4"
                >
                  <div
                    style={{ width: "14vw" }}
                    className="max-w-md flex-auto overflow-hidden rounded-xl text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 backdrop-blur-xl bg-white bg-opacity-40"
                  >
                    <div className="p-4">
                    <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 justify-center items-center text-center">
                        <div>
                          <a
                            href="/Register/"
                            className="font-semibold text-gray-900"
                          >
                            Register
                            <span className="absolute inset-0"></span>
                          </a>
                          
                        </div>
                      </div>

                      <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 justify-center items-center text-center">
                        <div>
                          <a
                            href="/JobPostings/"
                            className="font-semibold text-gray-900"
                          >
                            Browse Jobs
                            <span className="absolute inset-0"></span>
                          </a>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="">
              <a
                href="/FaqEmp/"
                className="-ml-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-500"
              >
                FAQs
              </a>
            </div> */}

            <div className="">
              <a
                href="/About/"
                className="block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-500"
              >
                How it works
              </a>
            </div>
          </div>

          <div className="hidden md:absolute md:inset-y-0 md:right-0 md:flex md:items-center md:justify-end">
            <a
              href="/Access"
              className="focus:shadow-outline-indigo inline-flex items-center rounded-md border border-transparent bg-purple-700 bg-opacity-90 hover:bg-opacity-80 px-4 py-2 text-base font-medium leading-6 text-gray-50 transition duration-150 ease-in-out hover:text-white hover:bg-purple-700 hover:bg-opacity-100 focus:border-purple-300 focus:outline-none active:bg-purple-500 active:text-gray-100 mr-4"
            >
              Sign in
            </a>

            <span className="inline-flex rounded-md shadow">
              <a
                className="focus:shadow-outline-indigo inline-flex items-center rounded-md border border-transparent bg-purple-700 bg-opacity-90 hover:bg-opacity-80 px-4 py-2 text-base font-medium leading-6 text-gray-50 transition duration-150 ease-in-out hover:text-white hover:bg-purple-700 hover:bg-opacity-100 focus:border-purple-300 focus:outline-none active:bg-purple-500 active:text-gray-100"
                // href="https://dasatva-job-interview.vercel.app/"
                href="/Contact/"
              >
                Contact us
              </a>
            </span>
          </div>
        </nav>
      </div>

      <div
        className="absolute inset-x-0 top-0 z-50 hidden origin-top-right transform p-2 transition"
        id="main-mobile-menu"
      >
        <div className="rounded-lg shadow-md">
          <div
            className="shadow-xs overflow-hidden rounded-lg bg-white"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="main-menu"
          >
            <div className="flex items-center justify-between px-5 pt-4">
              <div>
                {/* <img className="h-16 w-16" src="/assets/images/logo.png" alt=""> */}
              </div>
              <div className="-mr-2">
                <button
                  className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition duration-150 ease-in-out hover:bg-gray-100 hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
                  type="button"
                  aria-label="Close menu"
                  onClick={() =>
                    document
                      .getElementById("main-mobile-menu")
                      .classList.add("hidden")
                  }
                >
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3">
              <div className="space-y-2 py-2">
                <a
                  href="/"
                  className="mx-0 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-indigo-600 hover:bg-gray-50"
                >
                  Employers
                </a>
                <a
                  href="/Whyus/"
                  className="mx-0 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-indigo-600 hover:bg-gray-50"
                >
                  Why us
                </a>
                <a
                  href="/About"
                  className="mx-0 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-indigo-600 hover:bg-gray-50"
                >
                  How it works ?
                </a>

                
                <span className="mx-0 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-indigo-600 hover:bg-gray-50">
                  Job Seekers
                </span>
                <a
                  href="/Jobpostings/"
                  className="mx-0 block rounded-lg px-3 py-2 pl-6 text-base font-semibold leading-7 text-gray-700 hover:bg-white hover:bg-opacity-40"
                >
                  ↪ Browse Jobs
                </a>
                <a
                  href="/Register/"
                  className="mx-0 block rounded-lg px-3 py-2 pl-6 text-base font-semibold leading-7 text-gray-700 hover:bg-white hover:bg-opacity-40"
                >
                  ↪ Register with us
                </a>
                <span className="mx-0 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-indigo-600 hover:bg-gray-50">
                  Services
                </span>
                <a
                  href="/TemporaryStaffing/"
                  className="mx-0 block rounded-lg px-3 py-2 pl-6 text-base font-semibold leading-7 text-gray-700 hover:bg-white hover:bg-opacity-40"
                >
                  ↪ Temporary Staffing
                </a>
                <a
                  href="/DirectHire/"
                  className="mx-0 block rounded-lg px-3 py-2 pl-6 text-base font-semibold leading-7 text-gray-700 hover:bg-white hover:bg-opacity-40"
                >
                  ↪ Direct Hire
                </a>

                <a
                  href="/ContractHire/"
                  className="mx-0 block rounded-lg px-3 py-2 pl-6 text-base font-semibold leading-7 text-gray-700 hover:bg-white hover:bg-opacity-40"
                >
                  ↪ Contract to Hire
                </a>

                <a
                  href="/Payrolling/"
                  className="mx-0 block rounded-lg px-3 py-2 pl-6 text-base font-semibold leading-7 text-gray-700 hover:bg-white hover:bg-opacity-40"
                >
                  ↪ Payrolling
                </a>
                
              </div>
            </div>
            <div>
              <a
                className="bg-purple-600 bg-opacity-90 hover:bg-opacity-80 text-white block w-full bg-gray-50 px-5 py-3 text-center font-medium transition duration-150 ease-in-out hover:bg-gray-100 hover:text-indigo-700 focus:bg-gray-100 focus:text-indigo-700 focus:outline-none"
                href="/Contact/"
                role="menuitem"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Navbar;
