
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./About.css";
// eslint-disable-next-line
import { fireDb, fireStorage } from "../firebaseconfig";
import Logo from "../../Images/logo5122.png";
import partner1 from "../../StaffingPhotos/detailstaffing/manufactureopt2.jpg";
import partner2 from "../../StaffingPhotos/tempcolor.jpg";
import partner3 from "../../StaffingPhotos/mainpage5.jpg";
import partner4 from "../../StaffingPhotos/labour.jpg";
import partner5 from "../../StaffingPhotos/office.webp";
const images = [partner1, partner2, partner3, partner4, partner5];

export default function AboutEmp() {
  const [, setLoading] = useState(true);

  useEffect(() => {
    const preloadImages = () => {
      return Promise.all(
        images.map(
          (url) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;
              img.onload = resolve;
              img.onerror = reject;
            })
        )
      );
    };

    preloadImages()
      .then(() => setLoading(false))
      .catch((err) => {
        console.error("Image preloading failed", err);
        setLoading(false); // Set loading to false even if preloading fails
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div style={{ bottom: "10px" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "12px", // Adjusted width
          height: "12px", // Adjusted height to match width for a perfect circle
          color: "#4F46E5",
          borderRadius: "50%",
          border: "1px solid #4F46E5",
          background: "#4F46E5",
        }}
      />
    ),
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const submenu = document.getElementById("solutions-submenu");
    if (submenu) {
      submenu.classList.add("hidden");
    }
  }, []);

  const getFormattedDateTime = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Format date-time string with time
    return `${hours}:${minutes}-${day}-${month}-${year}`;
  };

  useEffect(() => {
    const isValid =
      firstName && lastName && companyName && industry && businessEmail;
    setFormValid(isValid);
  }, [firstName, lastName, companyName, industry, businessEmail, phoneNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Generate a unique ID based on the formatted date-time
    const uniqueId = getFormattedDateTime() + Math.floor(Math.random() * 1000); // Add random number for uniqueness

    try {
      const data = {
        firstName,
        lastName,
        companyName,
        industry,
        businessEmail,
        phoneNumber: phoneNumber || "",
      };

      // Push form data to Firebase with the unique ID
      await fireDb.child(`ContactRequests/${uniqueId}`).set(data);
      setFormSubmitted(true);
      console.log(
        "Form data pushed to Firebase successfully with ID:",
        uniqueId
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="navbar bg-white">
        <div className="relative pt-6 pb-0 sm:pb-4">
          <div className="mx-auto max-w-screen-xxl px-4 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
              <div className="flex flex-1 items-center md:absolute md:inset-y-0 md:left-0">
                <div className="flex w-full items-center justify-between md:w-auto">
                  <a href="/" aria-label="Home">
                    <img
                      className="md:h-32 md:w-40 lg:h-36 lg:w-52 h-28 w-32"
                      src={Logo}
                      alt="Dasatava"
                    />
                  </a>
                  <div className="-mr-2 flex items-center md:hidden">
                    <a
                      href="/Register/"
                      className="text-black block w-full px-5 py-3 text-center font-medium transition duration-150 ease-in-out hover:bg-gray-100  focus:bg-gray-100  focus:outline-none"
                      style={{
                        letterSpacing: "-0.02em",
                        fontFamily: "Work Sans, sans-serif",
                        fontSize: "16px",
                        lineHeight: "20px",
                        background: "none",
                        fontWeight: "900",
                      }}
                    >
                      Looking for work ?
                    </a>
                  </div>
                </div>
              </div>

              <div className="hidden md:absolute md:inset-y-0 md:right-0 md:flex md:items-center md:justify-end">
                <a
                  href="/Register/"
                  className="text-black block w-full px-5 py-3 text-center font-medium transition duration-150 ease-in-out hover:bg-gray-100 focus:bg-gray-100  focus:outline-none"
                  style={{
                    letterSpacing: "-0.02em",
                    fontFamily: "Work Sans, sans-serif",
                    fontSize: "16px",
                    lineHeight: "20px",
                    background: "none",
                    fontWeight: "900",
                  }}
                >
                  Looking for work ?
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className="min-h-screen bg-white text-white flex items-center justify-center p-4">
        <div className="max-w-6xl w-full flex flex-col md:flex-row">
          {/* Left Side Text Content */}
          <div className="md:w-1/2 p-8">
            <h1 className="text-5xl font-bold mb-4 text-black">
              Fill Your Employee Gaps Now
            </h1>
            <p className="text-lg mb-4 text-black">
              Streamline your Employee Staffing needs and reduce recruitment
              costs by 99% with our{" "}
              <span className="italic">zero placement fees*</span>
            </p>
            <p className="text-xs text-gray-400">
              *Staffing agencies hate us for this.
            </p>
            <div className="mt-32  opacity-30 hidden md:block">
              <Slider {...settings} className="">
                {images.map((src, index) => (
                  <div
                    key={index}
                    className="bg-indigo-50 rounded-2xl flex justify-center items-center"
                    style={{}}
                  >
                    <img
                      src={src}
                      alt={`Slide ${index + 1}`}
                      className="w-full object-cover rounded-2xl"
                      style={{}}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* Right Side Form */}
          <div className="max-w-lg mx-auto bg-white text-black px-8 pt-8 pb-4 rounded-2xl shadow-lg">
            {formSubmitted ? (
              <div className="text-center">
                <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
                <p className="text-lg mb-4">
                  Your request has been submitted successfully. We will get back
                  to you soon.
                </p>
                <a href="/" className="text-purple-500 font-bold ">
                  Return to Home
                </a>
              </div>
            ) : (
              <>
                <h2
                  className="text-center text-xl font-bold mb-6"
                  style={{ fontFamily: "Work Sans, sans-serif" }}
                >
                  First, a few details
                </h2>

                <form onSubmit={handleSubmit} className="space-y-8">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-gray-700">First name</label>
                      <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="John"
                        className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700">Last name</label>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Smith"
                        className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-gray-700">Company name</label>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      placeholder="SWASH Inc."
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Sector</label>
                    <select
                      value={industry}
                      onChange={(e) => setIndustry(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                      required
                    >
                      <option value="" disabled>
                        Choose One
                      </option>
                      <option value="Warehousing">Warehousing</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Hospitality&Catering">Hospitality & Catering</option>
                      <option value="Construction">Construction</option>
                      <option value="Office">Office</option>
                      <option value="Logistics&Transport">Logistics & Transport</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700">
                      Business email
                    </label>
                    <input
                      type="email"
                      value={businessEmail}
                      onChange={(e) => setBusinessEmail(e.target.value)}
                      placeholder="you@company-email.com"
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                      required
                    />
                    <p className="text-sm text-gray-500 mt-1">
                      Personal emails are not accepted (gmail, yahoo, icloud,
                      etc.). If you're a Job Seeker, sign up by{" "}
                      <a href="/Register/" className="text-gray-500 underline">
                        registering with us
                      </a>
                    </p>
                  </div>
                  <div>
                    <label className="block text-gray-700">Phone number</label>
                    <input
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="(555) 555-5555"
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className={`w-full py-3 rounded-lg mt-6 ${
                        formValid
                          ? "bg-purple-700 bg-opacity-90 hover:bg-opacity-80 text-white"
                          : "bg-gray-200 text-gray-500 cursor-not-allowed"
                      } font-bold`}
                      disabled={!formValid}
                    >
                      GET STARTED
                    </button>
                  </div>

                  <p className="text-sm text-center text-gray-500 mt-4">
                    By providing your email, you agree that Dasatva may contact
                    you
                    {/* as provided by our{" "}
                    <a href="/privacy-policy" className="text-gray-700 underline">
                      Privacy Policy
                    </a>
                    . */}
                  </p>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
