import React from 'react';
import Navbar from './Navbar';
import jobfair from '../Images/Careerfare.png';
import jobfair2 from '../Images/Jobfair2.webp';
import jobfair3 from '../Images/jobfair3.jpeg';


const Button = ({ to, children }) => ( // Pass `to` prop to specify the destination
  <a href={to} target="_blank" rel="noopener noreferrer"> {/* Use anchor tag with target="_blank" */}
    <button className="focus:shadow-outline-indigo inline-flex items-center rounded-md border border-transparent bg-purple-700 bg-opacity-90 hover:bg-opacity-80 px-4 py-2 text-base font-medium leading-6 text-gray-50 transition duration-150 ease-in-out hover:text-white hover:bg-purple-700 hover:bg-opacity-100 focus:border-purple-300 focus:outline-none active:bg-purple-500 active:text-gray-100">
      {children}
    </button>
  </a>
);

const Card = ({ imgSrc, imgAlt, buttonLabels, buttonLinks }) => (
  <div className="bg-white p-8 rounded-lg shadow-md max-w-lg w-full">
    <img src={imgSrc} alt={imgAlt} className="max-w-full h-auto mb-6" />
    <div className="flex justify-around">
      {buttonLabels.map((label, index) => (
        <Button key={index} to={buttonLinks[index]}>{label}</Button> // Pass the link destination as a prop
      ))}
    </div>
  </div>
);

export default function Smile() {
  return (
    <>
    <Navbar/>
    <div className="jobfair">
        <div className="text-center">
            <h1 className="text-3xl lg:text-4xl xl:text-5xl font-semibold mb-8 text-purple-400">
              Job Fairs in GTA
            </h1>
          </div>
 
    <div className="flex flex-col justify-center items-center space-y-8 pt-16">
    <Card imgSrc={jobfair3} imgAlt="Smiley face 2" buttonLabels={['Book Tickets']} buttonLinks={['https://www.eventbrite.ca/e/hire-canada-job-fair-training-expo-tickets-868543947327']} />
      <Card imgSrc={jobfair} imgAlt="Smiley face" buttonLabels={['Mississauga Fair', 'Toronto Fair']} buttonLinks={['https://www.eventbrite.ca/e/mississauga-career-fair-and-training-expo-canada-june-18-2024-tickets-722978386817?aff=oddtdtcreator&utm_medium=email&_hsenc=p2ANqtz-8kwB2avtd584L7oZODHFHGM4L9vAEA1WZfS2oiXgEC5ELkqYbUG76nyVh_1nJYS5kwQs_ViffuTOuvrcgWiWQAcjZ36w&_hsmi=310652143&utm_content=310652143&utm_source=hs_email', 'https://www.eventbrite.ca/e/toronto-career-fair-and-training-expo-canada-september-5-2024-tickets-777345400017?aff=oddtdtcreator&utm_medium=email&_hsenc=p2ANqtz--oiH7FQgboXkq3rXUX5GocoaxU864yk5pHxRelCOTUXawr4ETDcH0cMQQC7uqZTw9fFfhKSPE4enXAKozGsgKFi5kt-A&_hsmi=310652143&utm_content=310652143&utm_source=hs_email']} />
      <Card imgSrc={jobfair2} imgAlt="Smiley face 2" buttonLabels={['Book Tickets']} buttonLinks={['https://allevents.in/toronto/springboard-trades-and-skilled-labour-job-fair/10000891973275077']} />
     
    </div>
    </div>
    </>
  );
}
