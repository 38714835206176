
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import OurIndustries from "./OurIndustries";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import Reviews from "./Reviews";
import image2 from "../../../StaffingPhotos/Contract3.jpg"
import image1 from "../../../StaffingPhotos/contractwrkr.jpg"
import image3 from "../../../StaffingPhotos/Contract2.jpg"
import madesimple from "../../../StaffingPhotos/MadeSimple.png"
import sideimage from "../../../StaffingPhotos/Contractworker001.jpg"
import Ex2 from "../Ex2";
import GetStarted from "../GetStarted"
import Navbar from "../NavbarEmp";
import Footer from "../../Footer";
const images = [
  image1,image2,image3
];

export default function ContractHire() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const preloadImages = () => {
      return Promise.all(
        images.map(
          (url) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;
              img.onload = resolve;
              img.onerror = reject;
            })
        )
      );
    };

    preloadImages()
      .then(() => setLoading(false))
      .catch((err) => {
        console.error("Image preloading failed", err);
        setLoading(false); // Set loading to false even if preloading fails
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div style={{ bottom: "10px" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "12px", // Adjusted width
          height: "12px", // Adjusted height to match width for a perfect circle
          color: "#4F46E5",
          borderRadius: "50%",
          border: "1px solid #4F46E5",
          background: "#4F46E5",
        }}
      />
    ),
  };

  return (
    <>
      <Navbar />
     

      <div className="w-full relative mb-12" style={{ height: '70vh' }}>
  {loading ? (
    <div className="flex justify-center items-center" style={{ height: '70vh' }}>
      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full"></div>
    </div>
  ) : (
    <Slider {...settings} className="progress-slide-carousel relative">
      {images.map((src, index) => (
        <div
          key={index}
          className="bg-indigo-50 rounded-2xl flex justify-center items-center"
          style={{ height: '70vh' }}
        >
          <img
            src={src}
            alt={`Slide ${index + 1}`}
            className="w-full object-cover rounded-2xl"
            style={{ height: '70vh' }}
          />
        </div>
      ))}
    </Slider>
  )}
  
  {/* Text Container - Make sure it's inside the relative parent */}
  <div className="absolute inset-0 flex items-center justify-center text-center">
  <div className="text-white px-4">

<h1 className="font-semibold text-6xl md:text-8xl">Contract worker</h1>

<p className="mt-4 text-xl text-gray-200">
  Acquire top talent—when you need it.
  </p>
</div>
  </div>
</div>

      <Ex2 />
      <div className="flex flex-wrap items-center md:mt-20 mb-16">
        <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
          <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
            <img src={madesimple} alt="" srcset="" />
          </div>
          <h3 className="text-3xl mb-2 font-semibold leading-normal">
          Flexibility Meets Expertise
          </h3>
          <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
          When you need skilled professionals on a temporary basis, Dasatva Staffing’s Contract Hire services provide the perfect solution
          </p>
          <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
            Dasatva Staffing connects you with highly qualified candidates who can step in and deliver immediate results, whether it’s for a short-term project or seasonal demand. Enjoy the flexibility of contract hiring without compromising on talent, and keep your business running smoothly.
          </p>
          <p className="text-lg font-bold leading-relaxed mt-0 mb-4 text-gray-700">
            We are ready to assist with:
          </p>

          <ul className="list-none mt-6">
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 mr-3">
                    <FontAwesomeIcon icon={faHandPointRight} />
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">
                    Temporary staff for project-based workflows
                  </h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 mr-3">
                    <FontAwesomeIcon icon={faHandPointRight} />
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">
                    Permanent hires to achieve your long-term objectives
                  </h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 mr-3">
                    <FontAwesomeIcon icon={faHandPointRight} />
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">
                    Seasonal staffing to manage peak demand periods
                  </h4>
                </div>
              </div>
            </li>
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 mr-3">
                    <FontAwesomeIcon icon={faHandPointRight} />
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-600">
                    Direct hire placements for key established positions
                  </h4>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
            <img
              alt="..."
              src={sideimage}
              className="w-full align-middle rounded-t-lg"
            />
            <blockquote className="relative p-8 mb-4">
              <h4 className="text-xl font-bold text-blue">
                Top Notch Services
              </h4>
              <p className="text-md font-light mt-2 text-blue">
                We staff from warehouse associates to office supervisors,
                temporary placements to permanent jobs
                <strong style={{ color: "brown" }}> within 3 hours ! </strong>
              </p>
            </blockquote>
          </div>
        </div>
      </div>
          <OurIndustries/>
          <Reviews/>
          <GetStarted/>
          <Footer/>

      
    </>
  );
}




