// import React, { useEffect } from "react";
import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import SwamiShreeji from "./SwamiShreeji";
import Navbar from "./components/Navbar";
import Steps from "./steps";
import Soon from "./components/Soon";
import JobPosts from "./components/FreePostsings";
import About from "./components/About";
import Home from "./Home";
import PremiumService from "./components/PremiumService";
import Earn from "./components/Earn";
import JobFair from "./components/Jobfair";
import RegistrationClosed from "./components/RegistrationClosed";
import AboutEmp from "./components/Hire/AboutEmp"
import FaqEmp from "./components/Hire/FaqEmp"
import Whyus from "./components/Hire/Whyus"
import NavbarEmp from "./components/Hire/NavbarEmp"
import DirectHire from "./components/Hire/Staffing/DirectHire"
import TemporaryStaffing from "./components/Hire/Staffing/TemporaryStaffing";
import Payrolling from "./components/Hire/Staffing/Payrolling";
import ContractHire from "./components/Hire/Staffing/ContractHire";
import Access from "./components/Access/Access";
import Manufacturing from "./components/Hire/DetailStaffing/Manufacturing";
import Logistics from "./components/Hire/DetailStaffing/Logistics";
import Construction from "./components/Hire/DetailStaffing/Construction";
import Hospitality from "./components/Hire/DetailStaffing/Hospitality";
import Warehousings from "./components/Hire/DetailStaffing/Warehousings";
import Offices from "./components/Hire/DetailStaffing/Offices";
import Registration from "./components/Employee/Registration";



function App() {
  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault(); // Disable right-click
  //   };

  //   const handleKeyDown = (event) => {
  //     if (event.ctrlKey && (event.key === 'u' || event.key === 'U' || event.key === 'I' || event.key === 'i')) {
  //       event.preventDefault(); // Disable Ctrl+U and Ctrl+I
  //     }
  //   };

  //   document.addEventListener('contextmenu', handleContextMenu);
  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);
  return (
    <div>
    
      <Routes>
      <Route path="/" element={<Earn />} />
        <Route path="/Register" element={<Home />} />
        <Route path="/SwamiShreeji" element={<SwamiShreeji />} />
        
      
        <Route path="/DirectHire" element={<DirectHire />} />
        <Route path="/TemporaryStaffing" element={<TemporaryStaffing />} />
        <Route path="/Payrolling" element={<Payrolling />} />
        <Route path="/ContractHire" element={<ContractHire />} />


        <Route path="/Manufacturing" element={<Manufacturing />} />
        <Route path="/Logistics" element={< Logistics/>} />
        <Route path="/Construction" element={< Construction/>} />
        <Route path="/Office" element={< Offices/>} />
        <Route path="/Hospitality" element={< Hospitality/>} />
        <Route path="Warehousing/" element={<Warehousings/>} />

        
        <Route path="/FaqEmp" element={<FaqEmp />} />
        <Route path="/Whyus" element={<Whyus/>} />
        <Route path="/NavbarEmp" element={<NavbarEmp />} />
        <Route path="/RegistrationClosed" element={<RegistrationClosed />} />
        <Route path="/Jobfair" element={<JobFair />} />
        <Route path="/PremiumService" element={<PremiumService />} />
        <Route path="/Navbar" element={<Navbar />} />
        <Route path="/steps" element={<Steps />} />
        <Route path="/Soon" element={<Soon />} />
        <Route path="/JobPostings" element={<JobPosts />} />
        <Route path="/jobposting/:jobTitle/:uid" element={<JobPosts />} />

        <Route path="/About" element={<About />} />
        <Route path="/Access" element={<Access />} />
        <Route path="/Registration" element={<Registration />} />
   
        

        <Route path="/GetStarted" element={<AboutEmp />} />
        <Route path="/Contact" element={<AboutEmp />} />
      </Routes>
    
      
    </div>
  );
}

export default App;

// import React, { useState } from "react";
// import { Routes, Route } from "react-router-dom";
// import "./App.css";
// import SwamiShreeji from "./SwamiShreeji";
// import Navbar from "./components/Navbar";
// import Steps from "./steps";
// import FAQ from "./components/Faq";
// import Footer from "./components/Footer";
// import Soon from "./components/Soon";
// import FreePostings from "./components/FreePostsings";
// import About from "./components/About";
// import Home from "./Home";
// import ImagePopup from "./components/ImagePopup"; // import the popup component

// function App() {
//   const [showPopup, setShowPopup] = useState(true);

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   return (
//     <div>
//       {showPopup && <ImagePopup onClose={handleClosePopup} />}
//       <Navbar />
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/SwamiShreeji" element={<SwamiShreeji />} />
//         <Route path="/fAQ" element={<FAQ />} />
//         <Route path="/Navbar" element={<Navbar />} />
//         <Route path="/steps" element={<Steps />} />
//         <Route path="/Soon" element={<Soon />} />
//         <Route path="/FreePostings" element={<FreePostings />} />
//         <Route path="/About" element={<About />} />
//         <Route path="/Image" element={<About />} />
//       </Routes>
//       <Footer />
//     </div>
//   );
// }

// export default App;


/* <div>
            <div className="text-center">
              <h1 className="text-3xl lg:text-4xl xl:text-5xl font-semibold mb-8 text-gray-400">
                Job Postings
              </h1>
            </div>
            <ul>
              {jobs.map((job) => (
                <li key={job.id} className="mb-4">
                  <button
                    className="boxshdw text-gray-800 font-semibold px-4 py-2 rounded hover:bg-gray-200 container mx-auto px-4 py-8 relative"
                    onClick={() => handleJobClick(job)}
                  >
                    {job.title} - {job.location}
                    <span className="absolute bottom-0 right-0 mb-2 mr-4 text-xs text-gray-600 text-opacity-50">
                      Posted On: {job.postedDate}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          </div> */
         