import React, { useState, useEffect } from "react";
import loginImage from "../../Images/logo5122.png"; // Adjust the path as necessary
import './styles.css'; // Import the CSS file

export default function Access() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  useEffect(() => {
    // Check session storage for authentication status
    const isAuthenticated = sessionStorage.getItem("authenticated");
    if (isAuthenticated === "true") {
      setAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    // Access environment variables
    const storedUserId1 = process.env.REACT_APP_USER_ID;
    const storedPassword1 = process.env.REACT_APP_PASSWORD;
    const storedUserId2 = process.env.REACT_APP_USER_ID_2;
    const storedPassword2 = process.env.REACT_APP_PASSWORD_2;

    // Check if entered credentials match any of the predefined ones
    if (
      (userId === storedUserId1 && password === storedPassword1) ||
      (userId === storedUserId2 && password === storedPassword2)
    ) {
      // Store authentication status in session storage
      sessionStorage.setItem("authenticated", "true");
      setAuthenticated(true);
      setErrorMessage(""); // Clear error message on successful login
    } else {
      // Set error message on login failure
      setErrorMessage("Invalid username or password");
    }
  };

  const handleLogout = () => {
    // Clear authentication status from session storage
    sessionStorage.removeItem("authenticated");
    setAuthenticated(false);
  };

  if (!authenticated) {
    return (
      <div className="centered-container">
        <div className="form-container">
          <img
            src={loginImage}
            alt="Login Illustration"
            className="form-image"
          />
          <h2 
  className="mb-6 text-2xl text-gray-700" 
  style={{ fontFamily: 'Rubik, Arial, sans-serif' }}
>
   Employer Dashboard
</h2>

          <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
            <div className="mb-4">
              
              <input
                className="custom-input w-full"
                id="userId"
                type="text"
                placeholder="Email"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </div>
            <div className="mb-6">
              
              <input
                className="custom-input w-full"
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {errorMessage && (
              <div className="mb-4 p-4 bg-red-100 border border-red-300 rounded-lg text-red-600 text-sm">
                {errorMessage}
              </div>
            )}
            <div className="flex items-center justify-center">
              <button className="button-23" type="submit">Log In</button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <button onClick={handleLogout} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Logout</button>
      <div className="mt-4">
        <p>Welcome to the restricted content! This paragraph is only visible to authenticated users.</p>
      </div>
    </div>
  );
}
