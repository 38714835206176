import React, { useState } from "react";
import { fireDb, fireStorage } from "../components/firebaseconfig";
import "tailwindcss/tailwind.css";
import { FaPaperclip } from "react-icons/fa"; // Ensure you import this if you use it
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Add this import

function getFormattedDateTime() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12; // Convert hour 0 to 12
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  return `${day}-${month}-${year}-----${formattedHours}:${formattedMinutes}${ampm}`;
}

export default function RegistrationClosed() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [opportunity, setOpportunity] = useState("");
  const [workAuthorization, setWorkAuthorization] = useState("");
  const [license, setLicense] = useState("");
  const [jobType, ] = useState("");
  const [preferredShift, ] = useState("");
  const [resumeFile, setResumeFile] = useState(null);
  const [formValid, setFormValid] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setResumeFile(file); // Store the uploaded file
  };

  const validateForm = () => {
    const isValid =
      email &&
      firstName &&
      lastName &&
      phoneNumber &&
      address &&
      city &&
      opportunity &&
      workAuthorization &&
      license;
    setFormValid(isValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValid) return;

    const uniqueId = getFormattedDateTime() + Math.floor(Math.random() * 1000); // Add random number for uniqueness

    try {
      const data = {
        firstName,
        lastName,
        email,
        phoneNumber,
        address,
        city,
        opportunity,
        workAuthorization,
        license,
        jobType, // This is included in the form but was not part of validation
        preferredShift, // This is included in the form but was not part of validation
      };

      // Save form data to the database
      await fireDb.child(`JobNewApply/${uniqueId}`).set(data);
      console.log(
        "Form data pushed to Firebase successfully with ID:",
        uniqueId
      );

      // Handle file upload if a file is selected
      if (resumeFile) {
        // Create a storage reference
        const storageRef = ref(
          fireStorage,
          `ApplyResume/${uniqueId}_${resumeFile.name}`
        );

        // Upload the file
        await uploadBytes(storageRef, resumeFile);
        console.log("File uploaded successfully");

        // Optionally, get the download URL and update the database entry with it
        const downloadURL = await getDownloadURL(storageRef);
        await fireDb
          .child(`JobNewApply/${uniqueId}`)
          .update({ resumeLink: downloadURL });
        console.log("Resume link updated in the database");
      }

      setSubmitted(true);
    } catch (error) {
      console.error("Error:", error);
      // Optionally, display an error message to the user
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white text-black px-8 pt-8 pb-4 rounded-2xl shadow-lg">
      {submitted ? (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
          <p className="text-lg mb-4">
            Your request has been submitted successfully. We will get back to
            you soon.
          </p>
          <a href="/" className="text-purple-500 font-bold">
            Return to Home
          </a>
        </div>
      ) : (
        <>
          <h2
            className="text-center text-xl font-bold mb-6"
            style={{ fontFamily: "Work Sans, sans-serif" }}
          >
            Job Application
          </h2>

          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700">First name</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    validateForm();
                  }}
                  placeholder="John"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Last name</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    validateForm();
                  }}
                  placeholder="Smith"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-700">Email address</label>
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateForm();
                }}
                placeholder="you@example.com"
                className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Phone number</label>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  validateForm();
                }}
                placeholder="(555) 555-5555"
                className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Address</label>
              <input
                type="text"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  validateForm();
                }}
                placeholder="123 Main St."
                className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">City</label>
              <input
                type="text"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  validateForm();
                }}
                placeholder="New York"
                className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Opportunity</label>
              <select
                value={opportunity}
                onChange={(e) => {
                  setOpportunity(e.target.value);
                  validateForm();
                }}
                className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                required
              >
                <option value="" disabled>
                  Choose One
                </option>
                <option value="Full time">Full time</option>
                <option value="Part time">Part time</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700">Work Authorization</label>
              <select
                value={workAuthorization}
                onChange={(e) => {
                  setWorkAuthorization(e.target.value);
                  validateForm();
                }}
                className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                required
              >
                <option value="" disabled>
                  Choose One
                </option>
                <option value="Study Permit">Study Permit</option>
                <option value="Work Permit">Work Permit</option>
                <option value="Student on break">Student on break</option>
                <option value="Permanent Resident">Permanent Resident</option>
                <option value="Canadian Citizen">Canadian Citizen</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700">
                What License Do you have?
              </label>
              <input
                type="text"
                value={license}
                onChange={(e) => {
                  setLicense(e.target.value);
                  validateForm();
                }}
                placeholder="e.g., Driver's License"
                className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                required
              />
            </div>
            <div>
                    <label className="block text-gray-700">Upload Resume</label>
                    <label htmlFor="resume" className={`custom-file-label ${resumeFile ? 'file-selected' : ''} className="mt-1 block w-full border border-gray-300 rounded-md p-4"`}>
                    <FaPaperclip className="mr-2" />
                      {resumeFile ? resumeFile.name : 'Choose file'}
                      
                    </label>
                    <input
                      id="resume"
                      type="file"
                      accept=".pdf, .doc, .docx"
                      onChange={handleFileChange}
                      className="custom-file-input"
                    />
                  </div>
            
            <div>
              <button
                type="submit"
                className={`w-full py-3 rounded-lg mt-6 ${
                  formValid
                    ? "bg-purple-700 bg-opacity-90 hover:bg-opacity-80 text-white"
                    : "bg-gray-200 text-gray-500 cursor-not-allowed"
                } font-bold`}
                disabled={!formValid}
              >
                APPLY
              </button>
            </div>
            

            <p className="text-sm text-center text-gray-500 mt-4">
            <p className="text-sm text-gray-500 mt-1">
              Stay ahead in your job search by <a href="/Register/" className="text-gray-500 underline">
                        Registering 
                      </a> with us for upcoming opportunities
                      
                    </p>
              
            </p>
          </form>
        </>
      )}
    </div>
  );
}
