import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "../Hire/About.css";
import { fireDb, fireStorage } from "../firebaseconfig";
import Logo from "../../Images/logo5122.png";
import partner1 from "../../StaffingPhotos/detailstaffing/manufactureopt2.jpg";
import partner2 from "../../StaffingPhotos/tempcolor.jpg";
import partner3 from "../../StaffingPhotos/mainpage5.jpg";
import partner4 from "../../StaffingPhotos/labour.jpg";
import partner5 from "../../StaffingPhotos/office.webp";
import { FaPaperclip } from 'react-icons/fa'; 

const images = [partner1, partner2, partner3, partner4, partner5];

export default function Registration() {
  const [, setLoading] = useState(true);

  useEffect(() => {
    const preloadImages = () => {
      return Promise.all(
        images.map(
          (url) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;
              img.onload = resolve;
              img.onerror = reject;
            })
        )
      );
    };

    preloadImages()
      .then(() => setLoading(false))
      .catch((err) => {
        console.error("Image preloading failed", err);
        setLoading(false); // Set loading to false even if preloading fails
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div style={{ bottom: "10px" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "12px",
          height: "12px",
          color: "#4F46E5",
          borderRadius: "50%",
          border: "1px solid #4F46E5",
          background: "#4F46E5",
        }}
      />
    ),
  };
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [opportunity, setOpportunity] = useState("");
  const [workAuthorization, setWorkAuthorization] = useState("");
  const [license, setLicense] = useState("");
  const [jobType, setJobType] = useState("");
  const [preferredShift, setPreferredShift] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [resumeFile, setResumeFile] = useState(null); // State for file upload

  useEffect(() => {
    const isValid =
      firstName && lastName && email && phoneNumber && address && city && opportunity && workAuthorization && license && jobType && preferredShift;
    setFormValid(isValid);
  }, [firstName, lastName, email, phoneNumber, address, city, opportunity, workAuthorization, license, jobType, preferredShift]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setResumeFile(file); // Store the uploaded file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const uniqueId = getFormattedDateTime() + Math.floor(Math.random() * 1000); // Add random number for uniqueness
  
    try {
      const data = {
        firstName,
        lastName,
        email,
        phoneNumber,
        address,
        city,
        opportunity,
        workAuthorization,
        license,
        jobType,
        preferredShift,
      };
  
      // Save form data to the database
      await fireDb.child(`JobSeeker/${uniqueId}`).set(data);
      console.log("Form data pushed to Firebase successfully with ID:", uniqueId);
  
      // Handle file upload if a file is selected
      if (resumeFile) {
        // Create a storage reference
        const storageRef = fireStorage.child(`resumes/${uniqueId}_${resumeFile.name}`);
        
        // Upload the file
        await storageRef.put(resumeFile);
        console.log("File uploaded successfully");
  
        // Optionally, you can get the download URL and update the database entry with it
        const downloadURL = await storageRef.getDownloadURL();
        await fireDb.child(`JobSeeker/${uniqueId}`).update({ resumeLink: downloadURL });
        console.log("Resume link updated in the database");
      }
  
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getFormattedDateTime = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}-${day}-${month}-${year}`;
  };

  return (
    <>
      <div className="navbar bg-white">
        <div className="relative pt-6 pb-0 sm:pb-4">
          <div className="mx-auto max-w-screen-xxl px-4 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
              <div className="flex flex-1 items-center md:absolute md:inset-y-0 md:left-0">
                <div className="flex w-full items-center justify-between md:w-auto">
                  <a href="/" aria-label="Home">
                    <img
                      className="md:h-32 md:w-40 lg:h-36 lg:w-52 h-28 w-32"
                      src={Logo}
                      alt="Dasatava"
                    />
                  </a>
                  <div className="-mr-2 flex items-center md:hidden">
                    <a
                      href="/Register/"
                      className="text-black block w-full px-5 py-3 text-center font-medium transition duration-150 ease-in-out hover:bg-gray-100  focus:bg-gray-100  focus:outline-none"
                      style={{
                        letterSpacing: "-0.02em",
                        fontFamily: "Work Sans, sans-serif",
                        fontSize: "16px",
                        lineHeight: "20px",
                        background: "none",
                        fontWeight: "900",
                      }}
                    >
                      Looking for work ?
                    </a>
                  </div>
                </div>
              </div>

              <div className="hidden md:absolute md:inset-y-0 md:right-0 md:flex md:items-center md:justify-end">
                <a
                  href="/Register/"
                  className="text-black block w-full px-5 py-3 text-center font-medium transition duration-150 ease-in-out hover:bg-gray-100 focus:bg-gray-100  focus:outline-none"
                  style={{
                    letterSpacing: "-0.02em",
                    fontFamily: "Work Sans, sans-serif",
                    fontSize: "16px",
                    lineHeight: "20px",
                    background: "none",
                    fontWeight: "900",
                  }}
                >
                  Looking for work ?
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className="min-h-screen bg-white text-white flex items-center justify-center p-4">
        <div className="max-w-6xl w-full flex flex-col md:flex-row">
          {/* Left Side Text Content */}
          <div className="md:w-1/2 p-8">
            <h1 className="text-5xl font-bold mb-4 text-black">
            Fill Your Career with Excitement and Growth!
            </h1>
            <p className="text-lg mb-4 text-black">
            We are perpetually on the lookout for qualified candidates.{" "}
              
            </p>
            <p className="text-xs text-gray-400">
  <a href="/PremiumService" className="underline">
    *Post Employment Premium Service Fee Applied.
  </a>
</p>

            <div className="mt-32  opacity-30 hidden md:block">
              <Slider {...settings} className="">
                {images.map((src, index) => (
                  <div
                    key={index}
                    className="bg-indigo-50 rounded-2xl flex justify-center items-center"
                    style={{}}
                  >
                    <img
                      src={src}
                      alt={`Slide ${index + 1}`}
                      className="w-full object-cover rounded-2xl"
                      style={{}}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* Right Side Form */}
          <div className="max-w-lg mx-auto bg-white text-black px-8 pt-8 pb-4 rounded-2xl shadow-lg">
            {formSubmitted ? (
              <div className="text-center">
                <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
                <p className="text-lg mb-4">
                  Your request has been submitted successfully. We will get back
                  to you soon.
                </p>
                <a href="/" className="text-purple-500 font-bold ">
                  Return to Home
                </a>
              </div>
            ) : (
              <>
                <h2
                  className="text-center text-xl font-bold mb-6"
                  style={{ fontFamily: "Work Sans, sans-serif" }}
                >
                  First, a few details
                </h2>

                <form onSubmit={handleSubmit} className="space-y-8">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-gray-700">First name</label>
                      <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="John"
                        className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700">Last name</label>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Smith"
                        className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-gray-700">Email address</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="you@example.com"
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Phone number</label>
                    <input
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="(555) 555-5555"
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Address</label>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="123 Main St."
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">City</label>
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="New York"
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Opportunity</label>
                    <select
                      value={opportunity}
                      onChange={(e) => setOpportunity(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                      required
                    >
                      <option value="" disabled>
                        Choose One
                      </option>
                      <option value="Full time">Full time</option>
                      <option value="Part time">Part time</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700">Work Authorization</label>
                    <select
                      value={workAuthorization}
                      onChange={(e) => setWorkAuthorization(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                      required
                    >
                      <option value="" disabled>
                        Choose One
                      </option>
                      <option value="Study Permit">Study Permit</option>
                      <option value="Work Permit">Work Permit</option>
                      <option value="Student on break">Student on break</option>
                      <option value="Permanent Resident">Permanent Resident</option>
                      <option value="Canadian Citizen">Canadian Citizen</option>
                    </select>
                  </div>
                 
                  <div>
                    <label className="block text-gray-700">What License Do you have?</label>
                    <input
                      type="text"
                      value={license}
                      onChange={(e) => setLicense(e.target.value)}
                      placeholder="e.g., Driver's License"
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4 contactcustom-input"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Select Job Type</label>
                    <select
                      value={jobType}
                      onChange={(e) => setJobType(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                      required
                    >
                      <option value="" disabled>
                        Choose One
                      </option>
                      <option value="Technology">Warehousing</option>
                      <option value="Finance">Manufacturing</option>
                      <option value="Healthcare">Hospitality & Catering</option>
                      <option value="Education">Construction</option>
                      <option value="Manufacturing">Office</option>
                      <option value="Retail">Logistics & Transport</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700">Preferred Shift</label>
                    <select
                      value={preferredShift}
                      onChange={(e) => setPreferredShift(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md p-4"
                      required
                    >
                      <option value="" disabled>
                        Choose One
                      </option>
                      <option value="Day">Day</option>
                      <option value="Afternoon">Afternoon</option>
                      <option value="Night">Night</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700">Upload Resume</label>
                    <label htmlFor="resume" className={`custom-file-label ${resumeFile ? 'file-selected' : ''} className="mt-1 block w-full border border-gray-300 rounded-md p-4"`}>
                    <FaPaperclip className="mr-2" />
                      {resumeFile ? resumeFile.name : 'Choose file'}
                      
                    </label>
                    <input
                      id="resume"
                      type="file"
                      accept=".pdf, .doc, .docx"
                      onChange={handleFileChange}
                      className="custom-file-input"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className={`w-full py-3 rounded-lg mt-6 ${
                        formValid
                          ? "bg-purple-700 bg-opacity-90 hover:bg-opacity-80 text-white"
                          : "bg-gray-200 text-gray-500 cursor-not-allowed"
                      } font-bold`}
                      disabled={!formValid}
                    >
                      GET STARTED
                    </button>
                  </div>

                  <p className="text-sm text-center text-gray-500 mt-4">
                    By providing your email, you agree that Dasatva may contact
                    you
                  </p>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
// className="mt-1 block w-full border border-gray-300 rounded-md p-4"