import React, { useState } from "react";
import gsap from "gsap";
import "./abc.css";

function TextGallery({ texts }) {
  const [showOverflow, setShowOverflow] = useState(true);
  // eslint-disable-next-line
  const applyOverflow = () => {
    setShowOverflow(!showOverflow);
    const wrapper = document.querySelector(".container");
    if (showOverflow) {
      gsap.set(wrapper, { overflow: "hidden" });
    } else {
      gsap.set(wrapper, { overflow: "visible" });
    }
  };

  return (
    <div
      className="flex space-x-16 mt-2 mb-4 animate-loop-scrolls group-hover:paused"
      aria-hidden="true"
    >
      {texts.map((text, index) => (
         <div
         key={index}
         className="w-64 relative bg-slate-900  rounded-2xl border  border-b-0 flex-shrink-0 border-slate-700 px-8 py-14 md:w-[450px] sm:w-64" // Added sm:w-64 here
         style={{
          // bg-slate-900
           marginTop: "1em",
           marginBottom: "1em",
           
         }}
       > 


          <p className="text-base" style={{ marginTop: "-16px", color: "#ffffffad" }}>
            {text.text}
          </p>
          <div className="flex items-center mt-4 text-xl font-medium text-gray-500">
            <img
              src={text.imageUrl}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
              alt={"Here"}
            />
            <span>{text.heading}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TextGallery;
// text-loop-scroll group-hover:paused
