import React from "react";
import "./service.css";
import logs from "../Images/Logo.png";


export default function PremiumService() {
  return (
    <>
    
    <div className="flex justify-center">
      <div className="flex flex-col gap-4 mt-20 mx-auto max-w-6xl">
        <div className="flex flex-col gap-3 md:gap-5 TermsCon ">
          <div className="flex justify-center">
            <img className="w-64 h-64 cc" src={logs} alt="Logo" />
          </div>

          <h2 className="text-center text-3xl font-extrabold leading-9 text-gray-400 sm:text-4xl sm:leading-10">
            What is Post-Employment Premium Service Fee ?
          </h2>
          <p className="mt-2 text-base leading-6 text-gray-700">
            Post-Employment Premium Service Fee Terms and Conditions: The
            Post-Employment Premium Service Fee serves as a strategic investment
            in your professional trajectory.Post-Employment Premium Service Fee  includes the cost of your one-time Dasatva-provided Personal Protective Equipment (PPE), such as a safety vest, safety glasses, and other necessary gear. These are quality-approved products designed to ensure your safety in the workplace. Additionally, this fee covers the cost of mandatory training that will be provided before you begin your job.

{" "}
          </p>

          <p className="mt-2 text-base leading-6 text-gray-700">
            According to the current situation, we are aware of how difficult it
            is to be chosen for a job. For this reason, we have made the
            decision to
            <strong>
              {" "}
              charge a post-employment premium service fee of $100 to candidates
              who successfully secured a job 
            </strong>{" "}
            We will only charge this fee to candidates who have hired through
            us. This service charge is only necessary if you are hired. We will send you the offer letter,
            the start date of the employment, and any other pertinent documents
            as soon as we receive the service fee.{" "}

            
          </p>
          <p className="mt-2 text-base leading-6 text-gray-700">
           The post-employment premium service fee is non-refundable if you encounter any issues after joining the job, such as fewer hours or a lower pay rate than expected, the fee will not be refunded. However, in some cases, if there is a valid reason and the support team deems it appropriate, a partial refund may be offered
            

            
          </p>
        </div>
      </div>
    </div>
    </>
  );
}
