let jobs = [
  {
    id: 30,
    title: "Pharmacy Cashier",
    descriptionKey: "Job30",
    location: "Scarborough, ON",
    salary: "$18-$19/hr",
    postedDate: "November 08, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Employement Benifit",
   
  },
  {
    id: 29,
    title: "Pharmacy Student",
    descriptionKey: "Job29",
    location: "Milton, ON",
    salary: "$18-$20/hr",
    postedDate: "November 08, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Employement Benifit",
   
  },
  {
    id: 28,
    title: "Pharmacy Assistant",
    descriptionKey: "Job28",
    location: "Toronto, ON",
    salary: "$20-$25/hr",
    postedDate: "November 08, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Employement Benifit",
   
  },
  {
    id: 27,
    title: "Service Technician",
    descriptionKey: "Job27",
    location: "Milton, ON",
    salary: "$30-$35/hr",
    postedDate: "November 08, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Employement Benifit",
   
  },
  {
    id: 26,
    title: "Construction Team Lead",
    descriptionKey: "Job26",
    location: "St. Catharines, ON",
    salary: "$30-$40/hr",
    postedDate: "November 07, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Employement Benifit",
   
  },
  {
    id: 25,
    title: "Order Picker",
    descriptionKey: "Job25",
    location: "Saanichton, BC",
    salary: "$18-25/hr",
    postedDate: "November 07, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Dasatva PPE Kit",
   
  },
  {
    id: 24,
    title: "Shipper and Receiver",
    descriptionKey: "Job24",
    location: "Moncton, NB",
    salary: "$17-18/hr",
    postedDate: "November 07, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Dasatva PPE Kit",
   
  },
  {
    id: 23,
    title: "Shipper and Receiver",
    descriptionKey: "Job23",
    location: "Victoria, BC",
    salary: "$20–$25/hr",
    postedDate: "November 05, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Dasatva PPE Kit",
   
  },
  {
    id: 22,
    title: "Warehouse Associate",
    descriptionKey: "Job22",
    location: "Parksville, BC",
    salary: "$18–$19/hr",
    postedDate: "November 03, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Dasatva PPE Kit",
   
  },
  {
    id: 21,
    title: "Field Service Technician",
    descriptionKey: "Job21",
    location: "Barrie,ON",
    salary: "$25-$35/hour",
    postedDate: "November 03, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
   
  },
  {
    id: 20,
    title: "Supply Chain Manager",
    descriptionKey: "Job20",
    location: "Etobicoke,ON",
    salary: "$120k-$150k/yr",
    postedDate: "November 03, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Employement Benifit",
    keyword4: "Mid-Senior level",
  },
  {
    id: 19,
    title: "Maintenance Millwright",
    descriptionKey: "Job19",
    location: "Vaughan,ON ",
    salary: "$40-$45/hr",
    postedDate: "November 01, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Employement benifit",
    keyword4: "Associate level",
  },
  {
    id: 18,
    title: "Shift Miller",
    descriptionKey: "Job18",
    location: "Toronto, ON ",
    salary: "$50k-$55k/yr",
    postedDate: "November 01, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Comprehensive benefits",
   
  },
  {
    id: 17,
    title: "Forklift Operator",
    descriptionKey: "Job17",
    location: "Mississauga, ON ",
    salary: "$23-$24/hour",
    postedDate: "October 28, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Dasatva PPE Kit",
  },
  {
    id: 16,
    title: "Shipper(Receiver)",
    descriptionKey: "Job16",
    location: "Woodbridge, ON ",
    salary: "$25-$28/hour",
    postedDate: "October 28, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-site",
    keyword3: "Dasatva PPE Kit",
  },
  {
    id: 15,
    title: "Forklift Operator",
    descriptionKey: "Job15",
    location: "Mississauga, ON ",
    salary: "$23-$24/hour",
    postedDate: "October 26, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-site",
    keyword3: "Dasatva PPE Kit",
  },
  {
    id: 14,
    title: "Inventory Auditor",
    descriptionKey: "Job14",
    location: "Mississauga, ON ",
    salary: "$50,000–$60,000 a year",
    postedDate: "October 23, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-site",
    keyword3: "Dasatva PPE Kit",
  },
  {
    id: 13,
    title: "Production  Worker ",
    descriptionKey: "Job13",
    location: "Mississauga, ON ",
    salary: "$18/hour",
    postedDate: "October 18, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-site",
    keyword3: "Dasatva PPE Kit",
  },
  {
    id: 12,
    title: "General Factory Worker ",
    descriptionKey: "Job12",
    location: "North York, ON ",
    salary: "$17-$18/hour",
    postedDate: "October 18, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-site",
    keyword3: "Dasatva PPE Kit",
  },
  {
    id: 11,
    title: "Server ",
    descriptionKey: "Job11",
    location: "Toronto ",
    salary: "$17-$18/hour",
    postedDate: "October 15, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    
  },
  {
    id: 10,
    title: "Facility Manager ",
    descriptionKey: "Job10",
    location: "Mississauga ON ",
    salary: "$75k-$80k",
    postedDate: "October 15, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-site",
    
  },
  {
    id: 9,
    title: "Customer Service Representative ",
    descriptionKey: "Job9",
    location: "Quebec City, QC ",
    salary: "$20-$25/hour",
    postedDate: "October 06, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On-site",
    
  },
  {
    id: 6,
    title: "CNC Setup Operator ",
    descriptionKey: "Job6",
    location: "Calgary,AB ",
    salary: "$28-$35/hour",
    postedDate: "October 8, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On-Site",
    keyword3: "Dasatva PPE Kit",
  },
  {
    id: 1,
    title: "Fulltime Restaurant Cook",
    descriptionKey: "Job1",
    location: "Toronto",
    salary: "$23/hr + benefits",
    postedDate: "October 14, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On Site",
  },
  {
    id: 3,
    title: "Delivery Driver",
    descriptionKey: "Job3",
    location: "Toronto",
    salary: "$23/hr",
    postedDate: "October 13,2024", // Example date
    keyword1: "Full Time",
    
  },
  {
    id: 2,
    title: "General Labour",
    descriptionKey: "",
    location: "Toronto",
    salary: "$18/hour",
    postedDate: "October 12, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On Site",
    keyword3: "Dasatva PPE Kit",
  },

  {
    id: 7,
    title: "Food Line Worker",
    descriptionKey: "",
    location: "Toronto",
    salary: "$20/Hour",
    postedDate: "October 10, 2024", // Example date
    applyLink: "",
    keyword1: "Full Time",
    keyword2: "On Site",
    keyword3: "Dasatva PPE Kit",
  },
  {
    id: 8,
    title: "HeavyLoad Mover",
    descriptionKey: "Job8",
    location: "Richmond Hill",
    salary: "$17 - $20",
    postedDate: "October 8, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On Site",
    keyword3: "Dasatva PPE Kit",
  },

  {
    id: 4,
    title: "Kitchen Lead ",
    descriptionKey: "Job4",
    location: "Victoria, BC",
    salary: "$18/Hour+ Tip",
    postedDate: "October 7, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On Site",
    
  },

  {
    id: 5,
    title: "CNC Programmer ",
    descriptionKey: "Job5",
    location: "Mississauga",
    salary: "$25-$30/hr",
    postedDate: "October 6, 2024", // Example date
    keyword1: "Full Time",
    keyword2: "On Site",
    keyword3: "Dasatva PPE Kit",
  },
];


const jobDescriptions = {
  Job1: `
     Full time Cook <br><br>

    We are looking for a chef with previous experience working in high-end restaurants (serving banquets, wedding ceremonies, buffets, etc..<br>
    
    <br>
    
    Duties:<br><br>
    - Work according to the Mon-Sun schedule.<br>
    - English language - from CLB5..<br>
    - Portfolio - welcome!"<br>
    - Flexible hours and shifts tailored to your needs.<br>
    - Experience a safe, respectful, and inclusive workplace where authenticity is celebrated – welcoming individuals of all ages, backgrounds, and cultures.<br><br>
    
      Your Vibe and Experience:<br><br>
    -    We are looking for a chef with previous experience working in high-end restaurants (serving banquets, wedding ceremonies, buffets, etc..<br>
   
     
  
     
    `,
  Job2: `
    Operate CNC Mill machine (HAAS) 
 - Load and unload tools and raw materials in CNC machinery
 - Prepare and operate CNC machines to perform tasks such as drilling, grinding, milling


    `,
  Job3: `
    <br> 
    We are seeking a reliable and experienced delivery driver to join our team.    <br> If you have a G-full driving license and experience driving a 16ft truck, we want to hear from you!<br>

    <br>
    
    <strong> Duties:</strong><br><br>
    - Pickup and deliver items from lockers.<br>
    - Manage daily priorities related to logistics.<br>
    - Load and unload products for delivery.<br><br>
    
    <strong> Requirements:</strong><br>
    - G-full driving license.<br>
    - At least 1 year of experience driving a 16ft truck.<br>
    - Ability to lift 40 pounds.<br><br>
    
    <strong> Location:</strong>
    - Toronto.<br>
    
    <strong> Salary:</strong>
    - $23 per hour.<br><br>
    
    
    
      
    `,
  Job4: `

 About The Position:<br><br>

    <strong> Responsibilities:</strong><br>

    Preparing ingredients and dough for pancakes<br>
    ▪️Baking croissants, cookies and other products<br>
    ▪️Preparing pancakes and drinks<br>
    ▪️Willingness to work efficiently at a fast pace <br>
    ▪️Wash dishes, keep the place in order<br>
    ▪️Reception of goods and monitoring compliance with storage conditions<br>
    ▪️Compliance with sanitary and safety standards<br>
    ▪️Ability to work on feet for 8 hours. Ability to lift up to 25 kg.<br>
    ▪️Ability to work weekends and morning shifts (7 am)<br><br>


   <strong> Bonuses</strong><br>
 🔺Free food and drinks<br>
 🔺Close to metro (Yonge & eglinton)<br>
 🔺Young friendly team <br>
 🔺Full-time $18 + tip <br>
 <br>
 


    `,
  Job5: `
    CNC Programmer <br><br>
  
    Duties:<br>
    - Programmer for CNC Milling Machines 3-axis and Lathe Machines 2-axis<br>
    - Milling and Turning operations<br>
    - Set up and operate machine<br><br>

Required:<br>
-Knowledge in Mastercam<br>
- Be able to read and interpret blueprints<br>
- Basic English <br><br>

Location: Mississauga,ON<br><br>

Schedule: Mon-Fri  8am-4:30pm <br>

    `,
  Job6: `
    CNC Set-up Operator  <br><br>
  
    Duties:<br>
- Set-up machine or equipment controls and proper tools for specific operations for particular jobs<br>
 - Read and interpret engineering drawings/ blueprints<br>
 - Fit and assemble machined metal parts<br><br>

Required:<br>
-Experience at least 2 years <br>
- 3-5 axis machining experience CNC (HAAS)<br>
- Basic English <br><br>

Location: Calgary,AB<br><br>

Schedule: Mon-Fri  8am-5:0pm <br>

    `,
  Job7: `
    <br><br> <strong>Duties</strong>: 
  <br>
    - Perform a variety of cleaning activities such as sweeping, mopping and dusting <br>
    - Cleaning all surfaces in the kitchen and bathroom <br>
    - Handling all furniture and appliances carefully <br>
    - Experience at least 1 year  <br><br>

   <strong>Schedule</strong>: <br>
    3-4 times a month (On Thursday at 4pm) <br><br>
    <strong>Location</strong>: Toronto  <br><br>
    <strong>Salary</strong>: 20$ per hour <br><br><br>

  
    `,
  Job8: `
   
  
    <strong>Company</strong>: G FORCE Moving Company <br><br>
    
    <strong>Location</strong>: Richmond Hill <br><br>
  
    <strong>Job Type</strong>: Part-time <br><br>
  
    <strong>Job Description</strong>: <br>
    G FORCE Moving Company is seeking reliable and physically fit individuals to join our team as Movers for a move scheduled today (Saturday) at 2pm in the Richmond Hill area. <br><br>
  
    <strong>Responsibilities</strong>: <br>
    - Safely and efficiently load and unload furniture and other household items. <br>
    - Transport items from the current location to the moving truck and then to the new location. <br>
    - Ensure all items are properly secured and protected during transit. <br>
    - Follow instructions from the team leader and work collaboratively with other team members. <br>
    - Provide excellent customer service by handling items with care and respect. <br>
    - Perform other related tasks as required. <br><br>
  
    <strong>Requirements</strong>: <br>
    - Ability to lift and carry heavy items (up to 50 lbs) repeatedly throughout the day. <br>
    - Strong physical stamina and endurance. <br>
    - Punctuality and reliability. <br>
    - Good communication skills. <br>
    - Prior moving experience is a plus, but not required. <br>
    - Must be available to start work at 2pm today in Richmond Hill. <br><br>
  
    
  `,
  Job9: `
   
  
   
    <strong>Location</strong>: Quebec City, QC<br><br>
  
    <strong>Job Type</strong>: Full-time  <br><br>
  
    <strong>Job Description</strong>: <br>
    This is a Full-time, office role as a Customer Service Co-Ordinator  in Quebec City, QC. As a Customer Service Co-Ordinator, you will be responsible for managing new home warranty inquiries and ensuring customer satisfaction by providing exceptional service. <br><br>
  

  
    <strong>Requirements</strong>: <br>
    Excellent communication and interpersonal skills <br>
    Strong organizational and time-management abilities <br>
    Problem-solving skills and the ability to handle customer complaints effectively <br>
    Attention to detail and the ability to multitask in a fast-paced environment <br>
    Previous experience in customer service or a related field is preferred <br>

    
  `,
  Job10: `
   
  
   
    <strong>Location</strong>: Mississauga ON<br><br>
  
    <strong>Job Type</strong>: Full-time  <br><br>
  
    <strong>Job Description</strong>: <br>
     Dasatva is looking for an Associate Manager of Facilities Maintenance for a large retail company in Canada. This individual will be responsible for supporting store processes on continuous operations for recycling and waste removal, as well as dealing with the vendors directly for a clear plan on execution. We are looking for a candidate who has a strong understanding of back of house facility maintenance with containers and compactors. This individual will ensure services are expected to show up on time and through a schedule that makes sense cross functionally and what is best for the facility. <br><br>
  

  
    <strong>Requirements</strong>: <br>
    - 3-8 years' of experience working within Facilities<br>
    - Experience with project coordination<br>
    - Strong experience with vendor management/vendor relations dealing with different internal cross functional teams, outside vendors, and internal vendors<br>
    - Deep exposure to facilities with attributes such as exposure to HVAC systems, refrigeration, facilities equipment (ex. forklift)<br>
    - Experience working with occupational health and safety, working with ministry of labor<br>
    - Experience with working vendors that specialize in janitorial, waste management, garbage collection, recycling, transportation services<br>
    - Strong experience with scheduling services and creating a schedule for multiple large vendors<br>

    
  `,
  Job11: `
   
  
   
    <strong>Location</strong>: Toronto ON<br><br>
  
    <strong>Job Type</strong>: Full-time  <br><br>
  
    <strong>Job Description</strong>: <br>
    The Server will provide a selection of high-quality food and beverages to guests onboard. The successful candidate will be knowledgeable about all food offerings, cruise schedule and skyline attractions. The server will facilitate an amazing experience for their guests, be favorably viewed by supervisors and peers, and be swift and efficient in the execution of their assigned duties. <br><br>
  

  
    <strong>Requirements</strong>: <br>
   -  One (1) year experience in high volume restaurant required<br>
    - Smart Serve Certification required<br>
    - Energetic and enthusiastic personality essential<br>
    -  Must be able to effectively understand and convey written and verbal information to guests and coworkers<br>
    -  Must possess a service orientation – actively looking for ways to help others<br>
    -  Basic computer skills are required<br>
    -  Will work for extended periods without sitting<br>
    -  Ability to reach, bend, stoop, wipe, push, pull, move or lift up to 40 lbs (18 kgs)<br>
    -  Required to lift furniture and other heavy items such as dishes up and down stairs<br>
    -  Will be required to be available for work on all major holidays<br>
    -  If required, wear assigned Personal Protective Equipment (PPE) (i.e. non-slip shoes, gloves, weather appropriate dress attire, sun protection)<br>

    
  `,
  Job12: `
   
  
   
    <strong>Location</strong>:North York, ON<br><br>
  
    <strong>Job Type</strong>: Full-time  <br><br>

    <strong>Requirements</strong>: <br>
    Must be able to lift 50 pounds and be in good health. Able to stand while working. <br>

    Joob involves physical work candidate must be healthy and able to complete the tasks. <br>
    TTC (# 47) stop 100 yards from front door On Caledonia <br> <br>
    
    
    Bonus pay <br>
    Overtime pay <br>

    
    Casual dress <br>
    On-site parking <br>
  

    
    8 hour shift <br> <br>
    
    North York, ON: reliably commute or plan to relocate before starting work (required)
    Experience:
    
    industrial work: 1 year (preferred) <br>
    Work Location: In person <br>
    
    Expected start date: 2024-09-01 <br>
    
    &nbsp;

    
  `,
  Job13: `
   
  
   
  <strong>Location</strong>: Mississauga, ON<br><br>

  <strong>Job Type</strong>: Full-time  <br><br>

  <strong>Job Description</strong>: <br>
  We are seeking a dedicated Production Worker to join our client's team. The ideal candidate will be responsible for assisting in the manufacturing process, ensuring efficiency and quality in our production operations.<br><br>



  <strong>Requirements</strong>: <br>
-Ability to work with machinery<br>
  - Experience with forklift operation is a plus<br>
  - Previous experience in a warehouse or production environment<br>
  - Ability to follow instructions and work in a team setting<br>
  - Strong attention to detail and quality assurance skills<br>
  
`,
Job14: `
   
  
   
<strong>Location</strong>: Mississauga ON<br><br>

<strong>Job Type</strong>: Full-time  <br><br>

<strong>Job Description</strong>: <br>
As our Internal Auditor, you will be responsible for executing audits of actual processes to identify any risks and gaps from policies and procedures and industry best practices. The ideal candidate should have high attention to detail, strong investigative skills, the ability to work autonomously, and be adaptable to working in a fast-paced environment. Working directly with executives at the company, you will be responsible for identifying process improvements and offering solutions to mitigate risk. Ultimately, you will be responsible and in charge of developing and delivering an internal audit plan for the company. <br><br>



<strong>Requirements</strong>: <br>
-Bachelor’s degree in Business or a related field<br>
-3 years of experience in relevant internal/external audit space<br>
-Strong writing, research, and analysis/investigation skills<br>
-Capability to document information in a clear, structured, and comprehensive manner<br>
-Professional designation in the area (CPA or CA) or pursuing an audit designation is an immense asset<br>


`,

Job15: `
   
  
   
<strong>Location</strong>: Mississauga ON<br><br>

<strong>Job Type</strong>: Full-time  <br><br>

<strong>Job Description</strong>: <br>
Dasatva Staffing is currently hiring for Forklift Drivers to join one of the clients - one of the most renowned global sports and retail distribution center located in Mississauga, ON. We offer full time hours as per business needs and a friendly work environment. <br><br>



<strong>Requirements</strong>: <br>
Minimum of 2 years’ experience working as a forklift operator in a warehouse/shipping environment with a valid forklift operator license.<br>
Knowledge of workplace safety and safe lifting and handling procedures.<br>
Ability to work independently.<br>
Able to effectively communicate verbally and in writing.<br>
Knowledge of safe lifting and handling techniques.<br>
Must be able to carry and move equipment and/or supplies in excess of 40 pounds.<br>


`,
Job16: `
   
  
   
<strong>Location</strong>:Woodbridge, ON<br><br>

<strong>Job Type</strong>: Full-time, Day Shift<br><br>

<strong>Job Description</strong>:<br>
We are seeking a Shipper/Receiver for a full-time day shift position in a modern, clean manufacturing facility. The ideal candidate will have a minimum of 5 years of experience in shipping and receiving, with at least 3 years involving international shipping.<br><br>

<strong>Duties</strong>:<br>
- Complete necessary paperwork and manage the shipping of packages both domestically and internationally.<br>
- Receive incoming packages, inspect goods, and route materials to the appropriate department.<br>
- Oversee workflow in the packaging department to ensure shipments are prepared and dispatched by the requested date.<br>
- Maintain an inventory of shipping materials.<br>
- Pull stock and organize packages as needed.<br>
- Document transactions and manage the flow of controlled goods to and from stock.<br>
- Schedule and coordinate with carriers.<br>
- Perform general housekeeping tasks within the shipping and receiving area.<br><br>

<strong>Requirements</strong>:<br>
- 5+ years of experience as a Shipper/Receiver in a manufacturing environment.<br>
- High attention to detail with the ability to work independently with minimal supervision in a busy setting.<br>
- Extensive experience in preparing international shipments, including handling customs documents (NAFTA Cert, Commercial Invoice, B13).<br>
- In-depth knowledge of major couriers (FedEx, Purolator, UPS) and their respective software.<br>
- Proficiency in Microsoft Office (Outlook, Excel, Word, etc.).<br>
- Forklift License required.<br>
- Valid Driver’s License required.<br>
- Post-secondary education preferred.<br>
- Physical ability to lift up to 50 lbs.<br><br>

<strong>Note</strong>: Only candidates selected for an interview will be contacted due to the high volume of applications.



`,
Job17: `
   
  
   
<strong>Location</strong>: Mississauga ON<br><br>

<strong>Job Type</strong>: Full-time  <br><br>

<strong>Job Description</strong>: <br>
Dasatva Staffing is currently hiring for Forklift Drivers to join one of the clients - one of the most renowned global sports and retail distribution center located in Mississauga, ON. We offer full time hours as per business needs and a friendly work environment. <br><br>



<strong>Requirements</strong>: <br>
Minimum of 2 years’ experience working as a forklift operator in a warehouse/shipping environment with a valid forklift operator license.<br>
Knowledge of workplace safety and safe lifting and handling procedures.<br>
Ability to work independently.<br>
Able to effectively communicate verbally and in writing.<br>
Knowledge of safe lifting and handling techniques.<br>
Must be able to carry and move equipment and/or supplies in excess of 40 pounds.<br>


`,
Job18: `
   
  
   
<strong>Location</strong>: [Location not specified]<br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Job Description</strong>:<br>
The company is seeking a Heavy Duty Mechanic to perform a range of tasks essential for the safe and efficient application of technical skills in industrial settings. The role involves repairing and maintaining various industrial equipment, including Log Loaders, Wheel Loaders, Forklifts, and other mobile machinery used in lumber production. Ensuring high quality and adherence to safety and production standards is a key responsibility.<br><br>

<strong>Responsibilities</strong>:<br>
- Carry out repairs and maintenance on industrial equipment such as Log Loaders, Wheel Loaders, Forklifts, and other mobile machinery.<br>
- Maintain high quality and safety standards in all service activities.<br>
- Utilize technical skills and knowledge to ensure efficient operation of machinery.<br><br>

<strong>Requirements</strong>:<br>
- Valid Inter-Provincial or Provincial Journeyperson mechanic certification.<br>
- Ability to interpret manuals and schematics.<br>
- Knowledge of hydraulic systems.<br>
- Commitment to safety and maintaining a safe work environment.<br>
- Capability to work independently with minimal supervision.<br>
- Strong organizational and planning abilities.<br>
- Flexible schedule, including the ability to work day, evening, and weekend shifts.<br>
- Experience in a fleet shop setting.<br>
- Familiarity with Cummins and Cat engines.<br>
- Strong background in electrical systems.<br>
- Welding skills.<br><br>

<strong>Compensation Package</strong>:<br>
- Comprehensive benefits package, including medical, dental, pension, life insurance, disability coverage, accident insurance, vacation, and holidays.<br><br>

<strong>Note</strong>: Only candidates selected for an interview will be contacted due to the high volume of applications.



`,
Job19: `
   
  
   
<strong>Location</strong>:Vaughan,ON<br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Job Description</strong>:<br>
Our client is seeking a skilled technician to perform all repairs, develop and carry out preventative maintenance inspections, and follow the preventative maintenance schedule to ensure optimal performance of equipment. The role involves minimizing downtime and costs while complying with HACCP rules, JSA’s, SOP’s, and other requirements.<br><br>

<strong>Key responsibilities include </strong>:<br>
- Troubleshooting production line issues and identifying required spare parts.<br>
- Acting with a sense of urgency on-floor calls and emergencies to minimize downtime.<br>
- Completing work schedules in cooperation with departments and Maintenance & Production Supervisors.<br>
- Acting as a liaison for contractors conducting repairs in the plant.<br>
- Ensuring all required documentation is completed correctly and on time, including Daily Time Sheets, pre-operational forms, preventative maintenance logs, temperature recording, equipment monitoring, work orders, etc.<br>
- Performing work that may require skills in electrical, PLC Programming, refrigeration, hydraulics, pneumatics, vacuum, plumbing, fabricating, and other areas as needed.<br>
- Performing other duties as required.<br><br>

<strong>Requirements</strong>:<br>
- Millwright license (433A) required.<br>
- 5 years’ experience in food packaging equipment and industrial maintenance.<br>
- Good knowledge of electronics, PLC technologies, and troubleshooting skills.<br>
- Understanding of programmable controls and computer literacy.<br>
- Additional Skilled Trade Licenses are an asset.<br>
- Good writing and oral communication skills.<br>
- Ability to make decisions under pressure.<br>
- Willingness to work in conditions under 10˚C.<br>
- Responsible for compliance with Health and Safety, Good Manufacturing Practices, Food Safety, and Environmental Standards at all times.<br><br>

<strong>Work Conditions and Physical Capabilities</strong>:<br>
- Fast-paced environment.<br>
- Physically demanding – lifting up to 50 lbs.<br>
- Attention to detail.<br>
- Ability to work under pressure.<br>
- Handling heavy loads.<br>
- Hand-eye coordination.<br>
- Standing for extended periods.<br>
- Meeting production deadlines.<br>
- Cold/refrigerated environments.<br><br>

<strong>Note</strong>: Due to the high volume of applicants, only individuals selected for an interview will be contacted.


`,
Job20: `
   
  
   
<strong>Location</strong>: Etobicoke, ON<br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Job Description</strong>:<br>
A Canadian manufacturing company is seeking a Manager to lead their Production and Supply Chain operations. Working alongside the Engineering team, the Manager will be responsible for ensuring a reliable and efficient supply chain and manufacturing process using advanced lean principles.<br><br>

This company is experiencing rapid growth across multiple business streams, creating a dynamic and challenging role. Due to the company's expansion, career advancement and development are expected within the first year.<br><br>

They are looking for someone with a strong background in manufacturing, ideally from the automotive sector or a similar industry, who can manage inventory and ensure it flows efficiently through the production process.<br><br>

<strong>Requirements</strong>:<br>
- 10+ years of experience in Supply Chain/Manufacturing.<br>
- Strong management experience, including knowledge of HR laws and labor relations.<br>
- Expertise in Lean Manufacturing principles.<br>
- Strong ERP system knowledge.<br>
- Experience managing a P&L.<br><br>

<strong>Note</strong>: Due to the high volume of applicants, only individuals selected for an interview will be contacted.



`,
Job21: `
   
  
   
<strong>Location</strong>:Barrie,ON<br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Job Description</strong>:<br>
Dasatva Solutions Inc. is seeking a skilled Field Service Technician with expertise in refrigeration and processing plant operations. The role involves the precise installation, maintenance, and repair of advanced Deepchill slurry ice equipment. Ideal candidates are self-motivated and excel in both independent and team settings, with a strong commitment to delivering exceptional customer service.<br><br>

<strong>Key Responsibilities</strong>:<br>
- Perform expert installation, troubleshooting, and servicing of advanced industrial systems.<br>
- Assemble and install mechanical and electrical components with precision.<br>
- Use a range of hand tools and diagnostic equipment effectively.<br>
- Initiate system start-ups and conduct thorough operational tests.<br>
- Troubleshoot mechanical and electrical systems, with a focus on PLC systems.<br>
- Diagnose and repair refrigeration systems.<br>
- Conduct routine maintenance and service to ensure high customer satisfaction.<br>
- Manage customer inquiries and service tickets.<br><br>

<strong>Who We're Looking For</strong>:<br>
- Candidates with post-secondary education in a technical field such as Industrial Electrician, Millwright, or Refrigeration diploma, or equivalent experience.<br>
- Professionals willing to travel up to 70% domestically and internationally, and who can freely enter the United States.<br>
- Excellent command of English for effective communication with clients and team members; additional languages are considered an asset.<br>
- Eager learners who are dedicated to expanding their expertise.<br>
- Friendly problem-solvers who enjoy assisting customers.<br><br>

<strong>Skills and Experience Required</strong>:<br>
- 3-5 years of experience in a similar role within an industrial environment.<br>
- Proficiency with Siemens PLCs and expertise in refrigeration, electrical, and process control systems.<br>
- Strong mechanical and electrical background.<br><br>

<strong>Note</strong>: Due to the high volume of applicants, only individuals selected for an interview will be contacted.



`,
Job22: `
   
  
   
<strong>Location</strong>: Parksville, BC <br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Who You Are</strong>:<br>
- You’re quick with your hands and light on your feet.<br>
- You enjoy hard work and don’t shy away from lifting heavy items.<br>
- You’re reliable, have an alarm, and always arrive on time.<br>
- You’re proactive, taking initiative to get things done instead of waiting for things to happen.<br>
- You work well independently, but also collaborate effectively with others.<br>
- You like dogs.<br>
- You’re eager to learn new skills, including how to operate a forklift.<br><br>

<strong>What You'll Do</strong>:<br>
Duties and responsibilities include, but are not limited to:<br>
- Perform general warehouse duties.<br>
- Load and unload goods.<br>
- Label, pack, and stock items.<br>
- Palletizing and organizing shipments.<br>
- Picking and packing orders for delivery.<br><br>




<strong>Note</strong>: Due to the high volume of applicants, only individuals selected for an interview will be contacted.



`,
Job23: `
   
  
<strong>Location</strong>: Victoria, BC<br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Job Description</strong>:<br>
As a key member of the warehouse team, you will be responsible for receiving product inventory from vendors and ensuring accurate and timely shipping of sales orders to customers. This role requires attention to detail and effective communication with both the inside sales staff and the Branch Manager.<br><br>

<strong>Job Responsibilities</strong>:<br>
<strong>General Duties</strong>:<br>
- Maintain a clean and organized work area and warehouse.<br>
- Lock and secure all doors and windows at the end of each business day.<br>
- Ensure all company fleet vehicles on site are locked and secured overnight.<br>
- Assist customers in-store and over the phone as needed.<br>
- Maintain communication with inside sales staff and the Branch Manager regarding customer orders and backorders.<br><br>

<strong>Receiving Duties</strong>:<br>
- Match products received to packing slips, ensuring accuracy of items received.<br>
- Organize paperwork for accounting and receiving purposes, including shipping costs.<br>
- Write part numbers on boxes/packaging as needed.<br>
- Record any damages to goods upon arrival.<br>
- Notify the Branch Manager of any shipping errors from vendors or other branches.<br>
- Print and fulfill orders from received goods.<br>
- Organize goods for orders and/or restocking.<br>
- Communicate backorders with the purchasing department and Branch Manager.<br><br>

<strong>Shipping Duties</strong>:<br>
- Prioritize and pick orders based on urgency, such as customer needs, daily deliveries, and pickups.<br>
- Ensure product accuracy by double-checking orders after being picked.<br>
- Label all boxes and materials for each order.<br>
- Organize deliveries by driver or salesperson, and arrange for courier pickups when needed.<br>
- Attach waybills to appropriate receipts.<br>
- Pack promotional flyers into boxes for specific customers.<br>
- Contact customers for in-store pickups once orders are completed.<br>
- Ship and track all outbound orders and tool repairs, ensuring all paperwork and repair tickets are in order.<br>
- Ensure interbranch transfers are picked and shipped in a timely manner.<br>
- Notify the Branch Manager or staff if inventory levels require attention.<br><br>

<strong>Qualifications</strong>:<br>
- High school diploma or GED required.<br>
- Excellent listening and communication skills.<br>
- Strong attention to detail and organizational skills.<br>
- Positive attitude with a polite and friendly demeanor.<br>
- Must have a valid Driver’s License.<br><br>

<strong>Note</strong>: Only candidates selected for an interview will be contacted due to the high volume of applications.


`,
Job24: `
   
  
<strong>Location</strong>: Greater Moncton Area<br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Job Description</strong>:<br>
We Recruit. It’s that simple. At Dasatva Staffing, we connect with candidates daily to find the best local talent. Through a deep understanding of our clients' businesses, we transform today’s talent into tomorrow’s success.<br><br>

We are currently seeking General Labourers to work with our clients across various job sites in the Greater Moncton Area. These sites may include warehouses, production facilities, construction sites, and more.<br><br>

<strong>The Role</strong>:<br>
- Assist with general labour tasks around the jobsite.<br>
- Move, load, and unload equipment and supplies.<br>
- Work in indoor and outdoor environments.<br>
- Perform other duties as assigned.<br><br>

<strong>Requirements</strong>:<br>
- CSA Approved Steel Toe Work Boots.<br>
- CSA Approved Hard Hat.<br>
- CSA Approved Safety Vest.<br>
- Ability to lift up to 50 lbs frequently.<br>
- Previous experience as a general labourer is an asset.<br><br>

<strong>What We Offer</strong>:<br>
- $17.00 per hour plus 4% vacation pay.<br>
- Occupational Health and Safety training.<br>
- Prompt weekly payroll via direct deposit.<br><br>

<strong>Refer Talent. Get Rewarded</strong>:<br>
Know someone already working with Integrated Staffing? Ask about our Employee Referral Bonus. If you’re employed with Integrated Staffing and refer someone who joins our team, you can earn a $100 bonus. Certain rules and conditions apply. Contact one of our Atlantic Canadian offices for more details.

`,
Job25: `
   
<strong>Location</strong>: Saanichton, BC, V8M 1Z7<br><br>

<strong>Job Type</strong>: Full-time, Permanent, Afternoon Shift<br><br>

<strong>Position</strong>: Order Picker<br><br>



<strong>About the Company</strong>:<br>
B&C Foods is a leading provider of high-quality, customized centre-of-the-plate products in Western Canada. Our mission is to connect communities through great food and outstanding service. We are known for being a flexible and dependable partner, which is why restaurants, retailers, foodservice providers, and trading companies trust us. With branches across Western Canada and a network of business brands, we offer unmatched value to our partners.<br><br>

<strong>Key Responsibilities</strong>:<br>
- Pick and assemble orders for delivery or shipment, following company SOPs.<br>
- Operate carts, pallet jacks, and forklifts to pick products per customer order specifications, verifying product numbers.<br>
- Prepare orders for shipping or delivery according to company guidelines.<br>
- Adhere to safety and sanitation protocols in the warehouse.<br>
- Complete required logs and reports accurately.<br>
- Perform additional duties as assigned.<br><br>

<strong>Qualifications and Skills</strong>:<br>
- Previous experience is an asset but not required; training will be provided.<br>
- Strong commitment to safety practices and standards.<br>
- Comfortable working with raw meat products.<br>
- Willingness to work in a cold environment.<br>
- Physical ability to lift, carry, and move objects weighing 30+ lbs.<br>
- Physical fitness and stamina to handle prolonged activity, repetitive tasks, and manual labor.<br><br>

<strong>Benefits and Perks</strong>:<br>
- Dental and extended health care coverage for you and your family.<br>
- Life insurance.<br>
- Registered Retirement Savings Plan (RRSP) with company matching contributions.<br>
- Flexible schedule and paid time off.<br>
- Employee Assistance Program (support for mental health, financial services, legal needs).<br>
- Subsidized Company Share Ownership plan.<br>
- Employee pricing on thousands of high-quality foods.<br>
- Career advancement opportunities with ongoing training.<br>
- Instant Pay: Access your earnings immediately without waiting for payday.<br>
- Be part of a team passionate about food and long-term success.<br><br>

<strong>Note</strong>: Only applicants selected for an interview will be contacted due to the high volume of applications.

`,
Job26: `
   
<strong>Location</strong>: Jordan Station, Ontario<br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Position</strong>: Construction Team Lead<br><br>

<strong>Pay</strong>: $30.00 - $40.00 per hour (based on experience)<br><br>

<strong>Benefits</strong>:<br>
- Company vehicle provided<br>
- Dental and extended health care coverage<br>
- Vision care<br>
- Paid time off<br>
- Job site tools provided<br>
- Health and safety equipment training provided<br><br>

<strong>Responsibilities</strong>:<br>
- Lead and manage the construction team and subcontractors to ensure quality, budget, and project timelines are met from start to finish.<br>
- Hands-on work with the team while delivering high-quality craftsmanship and leadership.<br>
- Maintain positive relationships with clients, staff, and project partners.<br>
- Act as the on-site safety supervisor, ensuring safety regulations are enforced and potential hazards are identified and addressed.<br>
- Educate workers on safety protocols, and ensure site compliance with the Occupational Health and Safety Act.<br>
- Monitor daily work progress, addressing issues or escalating them as needed.<br>
- Communicate and manage project risks, ensuring client and company needs are met.<br>
- Review drawings to ensure that construction projects meet design, safety, and budget requirements.<br>
- Participate in project meetings and assist in the development and execution of project plans.<br>
- Evaluate the quality and efficiency of work on-site, assisting in employee performance evaluations.<br>
- Manage inventory, tracking tools, materials, and equipment.<br>
- Assist with other project tasks as required.<br><br>

<strong>Skills and Qualifications</strong>:<br>
- Minimum of 3 years of experience in a construction leadership role.<br>
- Post-secondary education in a construction-related field or equivalent experience.<br>
- Strong organizational and leadership abilities.<br>
- Proficient in computer and project management skills.<br>
- Solid knowledge of building codes and safety regulations.<br>
- Skilled in the use of construction tools and equipment.<br>
- Ability to interpret construction drawings and blueprints.<br>
- Analytical thinking and attention to detail.<br>
- Strong communication skills with a solution-focused mindset.<br><br>

<strong>Other Requirements</strong>:<br>
- Occasional travel and out-of-town work required. Company vehicle provided.<br>
- Regular workweek is 44 hours, with overtime expected as needed.<br>
- Must have excellent written and verbal skills in English.<br>
- Must be a permanent resident of Canada or hold a valid Canadian work permit.<br>
- Must have appropriate construction boots and helmet.<br><br>

<strong>Schedule</strong>:<br>
- 10-hour shifts, Monday to Friday.<br>
- Overtime as required.<br><br>

<strong>Note</strong>: Only applicants selected for an interview will be contacted due to the high volume of applications.

`,
Job27: `
   
<strong>Location</strong>: Toronto, ON<br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Position</strong>:  Service Technician<br><br>

<strong>Responsibilities</strong>:<br>
- Provide exceptional service to customers, adhering to Handicare’s terms and conditions.<br>
- Build and maintain strong relationships with customers and Handicare employees.<br>
- Accurately assess customer needs and recommend products and services accordingly.<br>
- Participate in training programs and demonstrate the ability to apply newly acquired knowledge and skills.<br>
- Submit accurate and timely reports as required.<br>
- Manage expenses and maintain inventory in line with Handicare’s guidelines.<br>
- Ensure compliance with Handicare, customer, and government Health and Safety regulations.<br>
- Perform regular maintenance and cleanliness of the assigned vehicle.<br>
- Responsible for maintaining vehicle and tool inventory, as well as ensuring all tools are in good condition.<br>
- Commit to delivering superior customer service.<br>
- Assist in training new hires through job shadowing and task demonstration when required.<br>
- Perform other duties as necessary.<br><br>

<strong>Education, Experience & Training</strong>:<br>
- High school diploma.<br>
- 2-4 years of experience or training in a technical support or repair role, preferably in the biomedical or a similar field.<br>
- Previous experience in the healthcare industry is an asset.<br><br>

<strong>Competencies</strong>:<br>
- Responds promptly to customer needs and seeks feedback to improve service.<br>
- Meets commitments and communicates effectively in various situations.<br>
- Respects organizational resources and upholds the company’s values.<br>
- Maintains a respectful and harassment-free work environment.<br>
- Follows policies, takes independent actions, and makes informed decisions.<br>
- Demonstrates sound judgment and adheres to safety and security procedures.<br>
- Reports unsafe conditions and ensures proper use of equipment and materials.<br><br>

<strong>Note</strong>: Only applicants selected for an interview will be contacted due to the high volume of applications.

`,
Job28: `
   
<strong>Position</strong>: Pharmacy Assistant<br><br>

<strong>Location</strong>:Toronto <br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Duties & Responsibilities</strong>:<br><br>

<strong>Customer Service</strong>:<br>
- Greet customers promptly and collect prescription information per company standards.<br>
- Perform tasks in order of priority based on the Role Assignment Schedule.<br>
- Refer all clinical questions to the pharmacist.<br>
- Provide customers with accurate wait-time expectations.<br>
- Retrieve and cash out prescriptions and additional items as needed.<br>
- Direct customers requiring counseling to the pharmacist.<br>
- Manage the dispensary cash register.<br>
- Adhere to confidentiality and privacy requirements at all times.<br><br>

<strong>Administration</strong>:<br>
- Accept new and refill prescription orders following Standard Operating Procedures.<br>
- Prepare and submit orders, excluding controlled substances and narcotics.<br>
- Handle third-party billing, online adjudication, and manual Third-Party Accounts.<br>
- Answer calls and refer as needed to a pharmacist.<br>
- Process incoming faxes in line with Pharmacy Standards of Practice.<br>
- Assist the pharmacist in organizing callbacks.<br>
- Accurately file completed documents according to Pharmacy Standards of Practice.<br><br>

<strong>Prescription Workflow</strong>:<br>
- Support in preparing prescriptions in accordance with company and professional standards.<br>
- Input data for new prescriptions and manage refill requests efficiently.<br>
- Select medications accurately per prescription according to Pharmacy Standards.<br>
- Count, measure, package, and label prescriptions as required.<br>
- Enter and maintain accurate data in the Pharmacy systems.<br><br>

<strong>Skills & Qualifications</strong>:<br>
- High School Diploma.<br>
- Completion of a Pharmacy Assistant accredited course preferred.<br>
- Prior experience in a pharmacy environment is an asset.<br>
- Strong organizational and multitasking abilities.<br>
- Excellent problem-solving skills.<br>
- High attention to detail.<br><br>

`,
Job29: `
   
<strong>Position</strong>: Pharmacy Student <br><br>

<strong>Location</strong>: Milton, ON<br><br>

<strong>Job Type</strong>: Full-time<br><br>

<strong>Job Summary</strong>:<br>
Join a locally owned and operated store dedicated to delivering health, beauty, and convenience services to the community, while keeping the customer at the heart of everything we do.<br><br>

<strong>Why This Role is Important</strong>:<br>
At our Pharmacy , we take pride in helping Canadians live life well. We’re seeking dedicated individuals passionate about creating an exceptional shopping experience for our Pharmacy customers at every step. This role offers a pathway for career growth, comprehensive training, flexible hours, and a generous benefits package.<br><br>

If you’re eager to be part of our growing Health & Wellness mission and help deliver a superior customer experience, this is the opportunity for you! Join us in a community pharmacy environment designed to provide practical training for future pharmacists and expand your real-world skills.<br><br>

<strong>Duties & Responsibilities</strong>:<br>

<strong>Pharmacy & Customer Service</strong>:<br>
- Greet and acknowledge all customers approaching the Pharmacy.<br>
- Maintain all privacy requirements, including customer confidentiality and consent management.<br>
- Ensure you are easily identifiable to the public as a Pharmacy staff member.<br><br>

<strong>Why This Role Stands Out</strong>:<br>
As part of our pharmacy's Health & Wellness philosophy, we’re committed to delivering top-tier service in a community setting. Our team supports the growth of aspiring pharmacists, providing:<br>
- Exposure to pharmacy practice within a community setting.<br>
- Development of communication skills with patients, colleagues, and healthcare providers.<br>
- Enhanced understanding of OTC products, disease management, and patient counseling.<br>
- Retail pharmacy management skills.<br>
- Practical experience that prepares future pharmacists to succeed in a community environment.<br><br>

<strong>What We Offer</strong>:<br>
- Progressive career opportunities.<br>
- Comprehensive training programs.<br>
- Flexible work schedules.<br>
- Competitive benefits package.<br><br>

If you’re ready to join a dynamic team with a commitment to health and community wellness, apply today to make an impact from day one!<br><br>


`,
Job30: `
   
<strong>Position</strong>: Pharmacy Cashier<br><br>

<strong>Location</strong>: Scarborough, ON<br><br>

<strong>Job Type</strong>: Full-time (30 hours/week)<br><br>

<strong>Job Overview</strong>:<br>
Join our pharmacy team and play a key role in providing outstanding customer service and supporting the day-to-day pharmacy operations. This is an excellent opportunity for individuals committed to quality service and interested in advancing their career within the pharmacy field.<br><br>

<strong>Responsibilities</strong>:<br>
- Assist and meet the needs of customers at the pharmacy checkout.<br>
- Collaborate with pharmacy technical assistants and pharmacists to deliver exceptional service.<br>
- Prepare and pack orders for delivery.<br>
- Manage orders and track the expired medication register.<br>
- Order necessary pharmacy supplies.<br>
- Sort prescriptions and manage document archives.<br>
- Handle the sending and receiving of faxes.<br>
- Ensure the pharmacy remains clean and organized.<br><br>

<strong>Requirements</strong>:<br>
- Strong organizational skills with attention to detail.<br>
- Excellent verbal communication abilities.<br>
- Problem-solving skills and ability to take initiative.<br>
- Ability to manage multiple tasks efficiently.<br><br>

<strong>Schedule</strong>:<br>
- 30 hours per week, full-time position.<br>
- Must be available for day, evening, and weekend shifts.<br><br>

<strong>Career Opportunities</strong>:<br>
- Opportunity to advance to a Pharmacy Technical Assistant role within six months, with full training provided.<br><br>

If you’re proactive, detail-oriented, and committed to providing exceptional service, apply now to join our dedicated pharmacy team!


`,
};

export default jobDescriptions;
export { jobs };
// git push origin master --force
